import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { colors, theme } from '../theme'
import { useBasketStore } from '../../store/BasketStore'
import { getProductImageSrc } from '../../utils/S3-links'

interface Props {
  product: {
    title: string
    images: string[]
    featuredImage?: string
    reference: string
    price: number
    brand: string
    chips: string[]
    promotion?: number
    deliveryPrice?: number
    fakeStock?: number
    bestSale?: boolean
    subscription?: boolean
  }
  animalId: string
}

export const PackCard: React.FC<Props> = ({ product, animalId }) => {
  const navigate = useNavigate()
  const splittedPrice = product.price.toString().split('.')

  const basketStore = useBasketStore()
  const handleAddToCart = (data: { quantity: number; color: string | null }) => {
    if (!product) return
    basketStore.addOrUpdateItem({
      reference: product.reference,
      quantity: data.quantity,
      price: product.subscription ? product.price * 12 : product.price,
      color: data.color || '',
      animalId: animalId || '',
    })
    window.navigator.vibrate(100)
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        gap: 1,
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        <Typography
          variant="h2"
          sx={{ fontWeight: 700, fontSize: '19px', textAlign: 'left', color: theme.palette.secondary.dark }}
        >
          Offre exclusive Petso
        </Typography>
        {product.promotion && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '18px',
              px: '16px',
              py: '2px',
              fontSize: '13.5px',
              fontWeight: 700,
              lineHeight: '16.8px',
              color: 'black',
              backgroundColor: theme.palette.primary.main,
            }}
          >
            - {product.promotion}%
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: 'fit-content',
            clipPath: 'polygon(0% 0%, 88% 0%, 100% 50%, 88% 100%, 0% 100%)',
            backgroundColor: theme.palette.primary.main,
            pr: '22px',
            pl: '6px',
            py: '2px',
            fontSize: '13px',
            fontWeight: 600,
          }}
        >
          {product.chips[0]}
        </Box>
      </Box>
      <img
        src={getProductImageSrc(product.featuredImage ?? product.images[0])}
        alt={product.title}
        style={{ height: '200px', width: '100%', maxHeight: '100%', objectFit: 'contain' }}
      />
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            px: '20px',
            py: '13px',
            borderRadius: '200px',
            color: 'black',
            backgroundColor: 'white',
            border: '1px solid black',
            fontSize: '14px',
            lineHeight: '16.2px',
            fontWeight: 600,
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: colors.lightGrey,
            },
            '&:disabled': {
              py: '13px',
              border: 'none',
              backgroundColor: 'rgba(243, 243, 245, 1)',
              color: '#696B6D',
            },
          }}
          onClick={() => {
            window.scrollTo(0, 0)
            navigate(`/produits/${product.reference}?animalId=${animalId}`)
          }}
        >
          {`Voir l'offre`}
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            px: '20px',
            py: '13px',
            borderRadius: '200px',
            color: 'white',
            border: '1px solid black',
            backgroundColor: theme.palette.secondary.main,
            fontSize: '14px',
            lineHeight: '16.2px',
            fontWeight: 600,
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: theme.palette.secondary.dark,
            },
            '&:disabled': {
              py: '13px',
              border: 'none',
              backgroundColor: 'rgba(243, 243, 245, 1)',
              color: '#696B6D',
            },
          }}
          onClick={(e) => {
            e.stopPropagation()
            handleAddToCart({ quantity: 1, color: null })
          }}
        >
          {`Acheter les trois pour : `}
          {`${splittedPrice[0]}`}
          <span style={{ fontSize: '12px', lineHeight: '14.2px' }}>
            {splittedPrice[1] ? `,${splittedPrice[1].length === 1 ? `${splittedPrice[1]}0` : splittedPrice[1]}` : ''}
          </span>
          {` €`}
        </Button>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%', mr: '40px' }}>
        <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '12px', textAlign: 'left', color: 'black' }}>
          Plus que {product.fakeStock ?? 2} disponibles !
        </Typography>
      </Box>
    </Box>
  )
}
