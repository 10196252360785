import { Typography, Box } from '@mui/material'
import React from 'react'
import { CategoryProps } from './WellbeingCategory'
import data from '../data/products.json'
import { ProductItem } from '../../Adoptions/ProductItem'

export const FoodCategory: React.FC<CategoryProps> = ({ animalProfile }) => {
  const species = animalProfile.species
  return (
    <>
      <Typography variant="h2" sx={{ fontWeight: 700, textAlign: 'center' }}>
        Alimentation
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 1 }}>
        {data.products
          .filter((product) => product.category.includes('alimentation'))
          .filter((product) => product.species.includes(species) || product.species.length === 0)
          .map((product) => (
            <ProductItem
              key={product.reference}
              product={product}
              animalId={animalProfile.id}
              animalName={animalProfile.name}
            />
          ))}
      </Box>
    </>
  )
}
