import { Box, Typography } from '@mui/material'
import React from 'react'
import { CategoryProps } from '../Shopping/Categories/WellbeingCategory'
import data from '../Shopping/data/products.json'
import { colors, theme } from '../theme'
import { ProductCard } from './ProductCard'
import Slider from 'react-slick'
import { PackCard } from './PackCard'

export const AccessoriesTab: React.FC<CategoryProps> = ({ animalProfile }) => {
  const species = animalProfile.species

  const settings = {
    dots: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    initialSlide: 0,
    speed: 200,
    arrows: false,
    customPaging: () => <div className="ft-slick__dots--custom"></div>,
  }
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Typography
            variant="h2"
            sx={{ fontWeight: 700, fontSize: '24px', textAlign: 'left', color: theme.palette.secondary.dark, mb: 2 }}
          >
            {`Préparez son arrivée !`}
          </Typography>
        </Box>
        <Box sx={{ mx: -2 }}>
          <hr
            style={{
              backgroundColor: colors.silver,
              height: '20px',
              width: '100%',
              border: 'none',
              margin: '0',
            }}
          />
        </Box>
        <Box sx={{ py: 2 }}>
          <Slider {...settings}>
            {data.products
              .filter((product) => product.category.includes('fidelami-pack'))
              .filter((product) => product.species.includes(species) || product.species.length === 0)
              .map((product) => (
                <PackCard key={product.reference} product={product} animalId={animalProfile.id} />
              ))}
          </Slider>
        </Box>
        <Box sx={{ mx: -2 }}>
          <hr
            style={{
              backgroundColor: colors.silver,
              height: '20px',
              width: '100%',
              border: 'none',
              margin: '0',
            }}
          />
        </Box>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            fontSize: '18px',
            textAlign: 'left',
            color: theme.palette.secondary.dark,
            px: '12px',
            py: '8px',
          }}
        >
          {`A la carte`}
        </Typography>
        {data.products
          .filter((product) => product.category.includes('fidelami'))
          .filter((product) => product.species.includes(species) || product.species.length === 0)
          .map((product) => (
            <ProductCard key={product.reference} product={product} animalId={animalProfile.id} />
          ))}
      </Box>
    </>
  )
}
