import React, { useEffect, useState } from 'react'
import {
  AdoptionAttemptDto,
  AdoptionAttemptDtoStatus,
  AnimalDto,
  AnimalHealthDtoSterilizedOrNeutered,
} from '../../interactors/gen/backendClient'
import { Avatar, Box, Typography, useMediaQuery } from '@mui/material'
import { getAnimalProfilePhotoSrc } from '../../utils/S3-links'
import { AnimalsClient } from '../../interactors/clients/AnimalsClient'
/* import { ReactComponent as TrashIcon } from '../icons/TrashIcon.svg' */
import { AdoptionStatusIndicator } from './AdoptionStatusIndicator'
import { useNavigate } from 'react-router-dom'
import { getReadableSpecies } from '../../domain/Animal/AnimalDisplay'
import { calculateAge } from '../../domain/Animal/calculateAge'

interface Props {
  adoption: AdoptionAttemptDto
}

export const AdoptionItem: React.FC<Props> = ({ adoption }) => {
  const [animal, setAnimal] = useState<AnimalDto | null>(null)
  const navigate = useNavigate()

  const handleAdoptionClick = () => {
    if (
      adoption.status === AdoptionAttemptDtoStatus.SuspendedByUser ||
      adoption.status === AdoptionAttemptDtoStatus.SuspendedByCustomer
    )
      return
    navigate(`./${adoption.id}`)
  }

  useEffect(() => {
    const fetchAnimal = async () => {
      const animal = await AnimalsClient.getAnimalById(adoption.animalId)
      setAnimal(animal)
    }

    fetchAnimal()
  }, [])
  const profileImageKey = animal?.images?.profileImageKey
  const isMobile = useMediaQuery('(max-width: 800px)')

  const displayAdoptionStatus = (): string => {
    if (adoption?.paymentDone || adoption.done) return 'Adoption terminée'

    if (
      adoption.status === AdoptionAttemptDtoStatus.SuspendedByUser ||
      adoption.status === AdoptionAttemptDtoStatus.SuspendedByCustomer
    ) {
      return 'Suspendue'
    }

    if (adoption?.certificate?.original && !adoption?.certificate?.signed) {
      return 'Signature certificat'
    }
    if (adoption?.contract?.original && !adoption?.contract?.signed) {
      return 'Signature contrat'
    }

    if (adoption?.contract?.signed && !(adoption?.paymentSentByUser || adoption?.paymentDone)) {
      return 'Régler'
    }

    return "Attente de l'asso"
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: { xs: 'unset', md: '98px' },
        border: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: '8px',
        p: '16px',
        cursor: 'pointer',
      }}
      onClick={handleAdoptionClick}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1, width: '100%' }}>
        <Avatar
          src={profileImageKey ? getAnimalProfilePhotoSrc(profileImageKey) : ''}
          alt={animal?.name}
          sx={{ height: '66px', width: '66px' }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: { xs: 'max-content', md: '33%' } }}>
            <Typography variant="body1" component="p" sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 700 }}>
              {animal?.name}
            </Typography>
            {!isMobile && (
              <Typography
                variant="body1"
                component="p"
                sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 400 }}
              >
                {`${animal?.species ? getReadableSpecies(animal.species) : ''}, ${
                  animal?.birthday?.date ? calculateAge(animal.birthday.date) : 'âge inconnu'
                }, ${
                  animal?.health.sterilizedOrNeutered === AnimalHealthDtoSterilizedOrNeutered.True
                    ? 'stérilisé'
                    : animal?.health.sterilizedOrNeutered === AnimalHealthDtoSterilizedOrNeutered.False
                    ? 'non stérilisé'
                    : 'stérilisation inconnue'
                }`}
              </Typography>
            )}
            {isMobile && <AdoptionStatusIndicator status={adoption.status} textToDisplay={displayAdoptionStatus()} />}
          </Box>
          {/* {!isMobile && (
            <>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '33%' }}>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 700 }}
                >
                  Type de l&#39;offre
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 400 }}
                >
                  {animal?.adoption.priceInEuros ? `Compensation ${animal?.adoption.priceInEuros}€` : 'Inconnu'}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '33%' }}>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 700 }}
                >
                  Interlocuteur
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 400 }}
                >
                  {adoption.customerName}
                </Typography>
              </Box>
            </>
          )} */}
        </Box>
        {!isMobile && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <AdoptionStatusIndicator status={adoption.status} textToDisplay={displayAdoptionStatus()} />
            {/* <TrashIcon  /> */}
          </Box>
        )}
      </Box>
    </Box>
  )
}
