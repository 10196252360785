import { Box, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getProductImageSrc } from '../../utils/S3-links'
import { colors, theme } from '../theme'

interface Props {
  product: {
    title: string
    images: string[]
    reference: string
    smallDescription?: string
    price: number
    oldPrice?: number
    promotion?: number
    deliveryPrice?: number
    subscription?: boolean
    chips: string[]
  }
  animalId: string
  animalName: string
}

export const ProductItem: React.FC<Props> = ({ product, animalId, animalName }) => {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '124px',
        borderRadius: '12px',
        backgroundColor: 'white',
        border: `2px solid ${colors.blueSky}`,
        cursor: 'pointer',
      }}
      onClick={() => {
        window.scrollTo(0, 0)
        navigate(`/produits/${product.reference}?animalId=${animalId}`)
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          gap: 1,
          width: '100%',
          height: '100%',
        }}
      >
        <img
          src={getProductImageSrc(product.images[0])}
          alt={product.title}
          style={{ height: '124px', width: '115px', maxHeight: '100%', objectFit: 'fill', borderRadius: '11px' }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '10px',
              width: '100%',
              alignItems: 'flex-start',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '8px',
                pr: '8px',
                width: '100%',
              }}
            >
              <Typography
                variant="body1"
                component="p"
                sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 700 }}
              >
                {product.title.replaceAll('votre animal', animalName || 'votre animal')}
              </Typography>
              {product.promotion && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '18px',
                    px: '16px',
                    py: '2px',
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: '16.8px',
                    color: theme.palette.secondary.main,
                    border: `2px solid ${theme.palette.secondary.main}`,
                  }}
                >
                  -{product.promotion}%
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                gap: '10px',
              }}
            >
              <Typography
                variant="body1"
                component="p"
                sx={{ fontSize: '13px', lineHeight: '18.2px', fontWeight: 600 }}
              >
                {product.smallDescription}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'end',
                  width: '100%',
                  gap: '6px',
                  pr: '8px',
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end', gap: '8px' }}>
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{ fontSize: '15px', lineHeight: '19.2px', fontWeight: 700, textAlign: 'right' }}
                  >
                    {product.price}€{product.subscription && '/mois'}
                  </Typography>
                  {product.oldPrice && (
                    <Typography
                      variant="body1"
                      component="p"
                      sx={{
                        fontSize: '12px',
                        lineHeight: '14.2px',
                        fontWeight: 700,
                        textDecoration: 'line-through',
                        textAlign: 'right',
                      }}
                    >
                      {product.oldPrice}€
                    </Typography>
                  )}
                </Box>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontSize: '12px', lineHeight: '14.2px', fontWeight: 400, color: 'black', textAlign: 'right' }}
                >
                  {product.deliveryPrice && product.deliveryPrice > 0
                    ? `Frais de port : ${product.deliveryPrice}€`
                    : 'Livraison offerte'}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
