import {
  AnimalDto,
  AnimalAdoptionDtoStatus,
  CreateAnimalBodyDtoSex,
  AnimalDtoSpecies,
  CreateAnimalBodyDtoVaccinationStatus,
  AnimalHealthDto,
  AnimalHealthDtoFeLV,
  AnimalHealthDtoFIV,
  AnimalHealthOperationDtoType,
  AnimalSearchItemDto,
  AnimalTakeOverDtoReason,
  AnimalAdoptionDtoCannotBeAdoptedStatus,
  AnimalAdoptionDtoStep,
  AdministrativeFollowUpDtoType,
  AnimalTakeOverDtoOrigin,
  AnimalDocumentDtoType,
  AnimalFormAndActivityDtoSize,
  AnimalFormAndActivityDtoActivity,
  AnimalFormAndActivityDtoCorpulence,
  AnimalAdoptionDtoOfferType,
  AdministrativeFollowUpDtoStatus,
  AnimalBreedDtoBreedTitle,
  AnimalHealthDtoDogCategory,
  VaccinationOperationDtoType,
  AnimalVaccinationDtoVaccines,
  TattooDtoLocation,
  ContactHostedFamilyFollowUpDtoType,
  AnimalHealthDtoSterilizedOrNeutered,
  AnimalHealthDtoPathologies,
  AnimalProfileHealthObservationsDtoType,
} from '../../interactors/gen/backendClient'
import { colors, theme } from '../../views/theme'
import { HostedFamilyFollowUpDtoType } from './HostedFamilyFollowUpDtoType'

export function getAnimalIcon(animal: AnimalDto): string {
  if (animal.sex === 'male' && animal.species === 'cat') {
    return '🐈‍⬛⚦'
  } else if (animal.sex === 'female' && animal.species === 'cat') {
    return '🐈♀'
  } else if (animal.sex === 'male' && animal.species === 'dog') {
    return '🐕⚦'
  } else if (animal.sex === 'female' && animal.species === 'dog') {
    return '🐩♀'
  }

  return ''
}

export const getReadableAnimalAdoptionStatus = (field: AnimalAdoptionDtoStatus): string => {
  const map = {
    [AnimalAdoptionDtoStatus.Adoptable]: 'Adoptable',
    [AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable]: "En cours d'adoption",
    [AnimalAdoptionDtoStatus.HasBeenAdopted]: 'Adopté',
    [AnimalAdoptionDtoStatus.CannotBeAdopted]: 'Non adoptable',
    [AnimalAdoptionDtoStatus.ToBeFilled]: 'À compléter',
  }

  return map[field]
}

export const getColorForAnimalAdoptionStatus = (field: AnimalAdoptionDtoStatus): string => {
  const map = {
    [AnimalAdoptionDtoStatus.Adoptable]: colors.cello,
    [AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable]: colors.amber,
    [AnimalAdoptionDtoStatus.HasBeenAdopted]: colors.seaGreen,
    [AnimalAdoptionDtoStatus.CannotBeAdopted]: colors.silver,
    [AnimalAdoptionDtoStatus.ToBeFilled]: theme.palette.error.main,
  }

  return map[field]
}

export const getReadableAnimalAdoptionStep = (field: AnimalAdoptionDtoStep): string => {
  const map = {
    [AnimalAdoptionDtoStep.AdoptionToBeValidated]: 'Adoption à valider ',
    [AnimalAdoptionDtoStep.AdoptionValidatedWithoutPostVisit]: 'Adoption validée (sans VPA)',
    [AnimalAdoptionDtoStep.AdoptionValidatedAfterPostVisit]: 'Adoption validée après VPA',
    [AnimalAdoptionDtoStep.PostVisitNotValidated]: 'VPA non validée',
    [AnimalAdoptionDtoStep.AnimalReturned]: `Animal retourné à l'association`,
    [AnimalAdoptionDtoStep.AnimalReserved]: `Animal réservé`,
  }

  return map[field]
}

export const getColorForAnimalAdoptionStep = (field: AnimalAdoptionDtoStep): string => {
  const map = {
    [AnimalAdoptionDtoStep.AnimalReserved]: colors.mintTulip,
    [AnimalAdoptionDtoStep.AdoptionToBeValidated]: colors.amber,
    [AnimalAdoptionDtoStep.AdoptionValidatedWithoutPostVisit]: colors.cello,
    [AnimalAdoptionDtoStep.AdoptionValidatedAfterPostVisit]: colors.seaGreen,
    [AnimalAdoptionDtoStep.PostVisitNotValidated]: colors.punch,
    [AnimalAdoptionDtoStep.AnimalReturned]: colors.mintTulip,
  }

  return map[field]
}

export const getReadableCannotBeAdoptedStatus = (
  field: AnimalAdoptionDtoCannotBeAdoptedStatus,
  opts: { short: boolean } = { short: false }
): string => {
  if (opts.short) {
    const map: Record<AnimalAdoptionDtoCannotBeAdoptedStatus, string> = {
      [AnimalAdoptionDtoCannotBeAdoptedStatus.Released]: 'Relaché',
      [AnimalAdoptionDtoCannotBeAdoptedStatus.Dead]: 'Décès',
      [AnimalAdoptionDtoCannotBeAdoptedStatus.InCare]: 'En soin',
      [AnimalAdoptionDtoCannotBeAdoptedStatus.LongTermWithHostedFamily]: `FALD`,
      [AnimalAdoptionDtoCannotBeAdoptedStatus.BeingSocialized]: `En sociabilisation`,
      [AnimalAdoptionDtoCannotBeAdoptedStatus.Lost]: 'Perdu',
      [AnimalAdoptionDtoCannotBeAdoptedStatus.TransferToAnotherAssociation]: 'Cédé à une autre asso',
      [AnimalAdoptionDtoCannotBeAdoptedStatus.InGestation]: 'En gestation',
      [AnimalAdoptionDtoCannotBeAdoptedStatus.InLactation]: 'En lactation',
    }

    return map[field]
  }

  const map: Record<AnimalAdoptionDtoCannotBeAdoptedStatus, string> = {
    [AnimalAdoptionDtoCannotBeAdoptedStatus.Released]: 'Relaché (Animal Libre)',
    [AnimalAdoptionDtoCannotBeAdoptedStatus.Dead]: 'Décès',
    [AnimalAdoptionDtoCannotBeAdoptedStatus.InCare]: 'En soin',
    [AnimalAdoptionDtoCannotBeAdoptedStatus.LongTermWithHostedFamily]: `Famille d'accueil longue durée`,
    [AnimalAdoptionDtoCannotBeAdoptedStatus.BeingSocialized]: `En sociabilisation`,
    [AnimalAdoptionDtoCannotBeAdoptedStatus.Lost]: 'Perdu',
    [AnimalAdoptionDtoCannotBeAdoptedStatus.TransferToAnotherAssociation]: 'Cédé à une autre association',
    [AnimalAdoptionDtoCannotBeAdoptedStatus.InGestation]: 'En gestation',
    [AnimalAdoptionDtoCannotBeAdoptedStatus.InLactation]: 'En lactation',
  }

  return map[field]
}

export const getColorForCannotBeAdoptedStatus = (field: AnimalAdoptionDtoCannotBeAdoptedStatus): string => {
  const map: Record<AnimalAdoptionDtoCannotBeAdoptedStatus, string> = {
    [AnimalAdoptionDtoCannotBeAdoptedStatus.Released]: colors.cello,
    [AnimalAdoptionDtoCannotBeAdoptedStatus.Dead]: colors.black,
    [AnimalAdoptionDtoCannotBeAdoptedStatus.InCare]: colors.azalea,
    [AnimalAdoptionDtoCannotBeAdoptedStatus.LongTermWithHostedFamily]: colors.mintTulip,
    [AnimalAdoptionDtoCannotBeAdoptedStatus.BeingSocialized]: colors.anakiwa,
    [AnimalAdoptionDtoCannotBeAdoptedStatus.Lost]: colors.silver,
    [AnimalAdoptionDtoCannotBeAdoptedStatus.TransferToAnotherAssociation]: colors.amber,
    [AnimalAdoptionDtoCannotBeAdoptedStatus.InGestation]: colors.azalea,
    [AnimalAdoptionDtoCannotBeAdoptedStatus.InLactation]: colors.azalea,
  }

  return map[field]
}

export const getReadableAnimalVaccineStatus = (field: CreateAnimalBodyDtoVaccinationStatus): string => {
  const map: Record<CreateAnimalBodyDtoVaccinationStatus, string> = {
    [CreateAnimalBodyDtoVaccinationStatus.ToDo]: 'À initier',
    [CreateAnimalBodyDtoVaccinationStatus.InProgress]: 'En cours',
    [CreateAnimalBodyDtoVaccinationStatus.Completed]: 'Complet',
    [CreateAnimalBodyDtoVaccinationStatus.Unknown]: 'Ne sais pas',
    [CreateAnimalBodyDtoVaccinationStatus.NotDoing]: "N'est pas concerné",
  }

  return map[field]
}

export const getReadableAnimalSterilizedOrNeutered = (field: AnimalHealthDtoSterilizedOrNeutered): string => {
  const map: Record<AnimalHealthDtoSterilizedOrNeutered, string> = {
    [AnimalHealthDtoSterilizedOrNeutered.False]: 'Non',
    [AnimalHealthDtoSterilizedOrNeutered.True]: 'Oui',
    [AnimalHealthDtoSterilizedOrNeutered.Unknown]: 'Ne sais pas',
  }

  return map[field]
}

export const getColorForAnimalVaccineStatus = (field: CreateAnimalBodyDtoVaccinationStatus): string => {
  const map: Record<CreateAnimalBodyDtoVaccinationStatus, string> = {
    [CreateAnimalBodyDtoVaccinationStatus.ToDo]: colors.mintTulip,
    [CreateAnimalBodyDtoVaccinationStatus.InProgress]: colors.amber,
    [CreateAnimalBodyDtoVaccinationStatus.Completed]: colors.seaGreen,
    [CreateAnimalBodyDtoVaccinationStatus.Unknown]: colors.athensGray,
    [CreateAnimalBodyDtoVaccinationStatus.NotDoing]: colors.azalea,
  }

  return map[field]
}

export const getReadableOperationType = (field: AnimalHealthOperationDtoType): string => {
  const map: Record<AnimalHealthOperationDtoType, string> = {
    [AnimalHealthOperationDtoType.Deworming]: 'Vermifuge',
    [AnimalHealthOperationDtoType.Others]: 'Autres visites vétérinaires',
    [AnimalHealthOperationDtoType.Identification]: 'Identification',
    [AnimalHealthOperationDtoType.SterilizedOrNeutered]: 'Stérilisation / Castration',
    [AnimalHealthOperationDtoType.FleaControl]: 'Antipuce',
    [AnimalHealthOperationDtoType.Tatoo]: 'Tatouage',
    [AnimalHealthOperationDtoType.Implant]: 'Implant',
    [AnimalHealthOperationDtoType.EarMarking]: "Marquage à l'oreille",
    [AnimalHealthOperationDtoType.GoodHealthCertificate]: 'Certificat de bonne santé',
    [AnimalHealthOperationDtoType.AnnualReminder]: 'Rappel annuel',
    [AnimalHealthOperationDtoType.FirstInjection]: 'Première injection',
    [AnimalHealthOperationDtoType.SecondInjection]: 'Deuxième injection',
    [AnimalHealthOperationDtoType.ThirdInjection]: 'Troisième injection',
    [AnimalHealthOperationDtoType.Surgery]: 'Chirurgie',
    [AnimalHealthOperationDtoType.IdentificationChip]: "Puce d'identification",
    [AnimalHealthDtoPathologies.CanineDistemper]: 'Test Maladie de carré',
    [AnimalHealthDtoPathologies.RubarthHepatitis]: 'Test Hépatite de Rubarth',
    [AnimalHealthDtoPathologies.Leptospirosis]: 'Test Leptospirose',
    [AnimalHealthDtoPathologies.Rabies]: 'Test Rage',
    [AnimalHealthDtoPathologies.Parvovirus]: 'Test Parvovirose',
    [AnimalHealthDtoPathologies.KennelCough]: 'Test Toux de chenil',
    [AnimalHealthDtoPathologies.Herpesvirus]: 'Test Herpèsvirose',
    [AnimalHealthDtoPathologies.Babesiosis]: 'Test Piroplasmose',
    [AnimalHealthDtoPathologies.LymeDisease]: 'Test Maladie de Lyme',
    [AnimalHealthDtoPathologies.Diabetic]: 'Test Diabète',
    [AnimalHealthDtoPathologies.Typhus]: 'Test Typhus',
    [AnimalHealthDtoPathologies.Chlamydiosis]: 'Test Chlamydiose',
    [AnimalHealthDtoPathologies.Coryza]: 'Test Coryza',
    [AnimalHealthOperationDtoType.Fiv]: 'Test FIV',
    [AnimalHealthOperationDtoType.FeLv]: 'Test FeLV',
    [AnimalHealthDtoPathologies.Fip]: 'Test PIF',
    [AnimalHealthDtoPathologies.CatScratchDisease]: 'Test Maladie des griffes du chat',
    [AnimalHealthOperationDtoType.Hospitalization]: 'Hospitalisation',
    [AnimalHealthOperationDtoType.BloodTest]: 'Prise de sang',
    [AnimalHealthOperationDtoType.Treatment]: 'Traitement',
    [AnimalHealthOperationDtoType.MedicalAppointment]: 'Consultation',
    [AnimalHealthDtoPathologies.Giardiasis]: 'Test Giardiose',
  }

  return map[field]
}

export const getColorForOperationType = (field: AnimalHealthOperationDtoType): string => {
  const map: Record<AnimalHealthOperationDtoType, string> = {
    [AnimalHealthOperationDtoType.Deworming]: colors.mintTulip,
    [AnimalHealthOperationDtoType.Others]: colors.cello,
    [AnimalHealthOperationDtoType.Identification]: colors.seaGreen,
    [AnimalHealthOperationDtoType.SterilizedOrNeutered]: colors.azalea,
    [AnimalHealthOperationDtoType.FleaControl]: colors.mintTulip,
    [AnimalHealthOperationDtoType.Tatoo]: colors.seaGreen,
    [AnimalHealthOperationDtoType.Implant]: colors.azalea,
    [AnimalHealthOperationDtoType.EarMarking]: colors.seaGreen,
    [AnimalHealthOperationDtoType.GoodHealthCertificate]: colors.amber,
    [AnimalHealthOperationDtoType.AnnualReminder]: colors.anakiwa,
    [AnimalHealthOperationDtoType.FirstInjection]: colors.anakiwa,
    [AnimalHealthOperationDtoType.SecondInjection]: colors.anakiwa,
    [AnimalHealthOperationDtoType.ThirdInjection]: colors.anakiwa,
    [AnimalHealthOperationDtoType.Surgery]: colors.azalea,
    [AnimalHealthOperationDtoType.IdentificationChip]: colors.seaGreen,
    [AnimalHealthDtoPathologies.CanineDistemper]: colors.amber,
    [AnimalHealthDtoPathologies.Giardiasis]: colors.amber,
    [AnimalHealthDtoPathologies.RubarthHepatitis]: colors.amber,
    [AnimalHealthDtoPathologies.Leptospirosis]: colors.amber,
    [AnimalHealthDtoPathologies.Rabies]: colors.amber,
    [AnimalHealthDtoPathologies.Parvovirus]: colors.amber,
    [AnimalHealthDtoPathologies.KennelCough]: colors.amber,
    [AnimalHealthDtoPathologies.Herpesvirus]: colors.amber,
    [AnimalHealthDtoPathologies.Babesiosis]: colors.amber,
    [AnimalHealthDtoPathologies.LymeDisease]: colors.amber,
    [AnimalHealthDtoPathologies.Diabetic]: colors.amber,
    [AnimalHealthDtoPathologies.Typhus]: colors.amber,
    [AnimalHealthDtoPathologies.Chlamydiosis]: colors.amber,
    [AnimalHealthDtoPathologies.Coryza]: colors.amber,
    [AnimalHealthOperationDtoType.Fiv]: colors.amber,
    [AnimalHealthOperationDtoType.FeLv]: colors.amber,
    [AnimalHealthDtoPathologies.Fip]: colors.amber,
    [AnimalHealthDtoPathologies.CatScratchDisease]: colors.amber,
    [AnimalHealthOperationDtoType.Hospitalization]: colors.cello,
    [AnimalHealthOperationDtoType.BloodTest]: colors.cello,
    [AnimalHealthOperationDtoType.Treatment]: colors.cello,
    [AnimalHealthOperationDtoType.MedicalAppointment]: colors.cello,
  }

  return map[field]
}

export const getReadableHealthObservationsType = (issue: AnimalProfileHealthObservationsDtoType): string => {
  const map: Record<AnimalProfileHealthObservationsDtoType, string> = {
    // Cat specific issues
    [AnimalProfileHealthObservationsDtoType.DiarrheaAndStoolIssues]: 'Diarrhée & Excréments',
    [AnimalProfileHealthObservationsDtoType.Vomiting]: 'Vomissements',
    [AnimalProfileHealthObservationsDtoType.MeowingInLitter]: 'Miaule dans sa litière',
    [AnimalProfileHealthObservationsDtoType.AppetiteChange]: "Changement d'appétit",
    [AnimalProfileHealthObservationsDtoType.ExcessiveWeightGain]: 'Prise de poids excessive',
    [AnimalProfileHealthObservationsDtoType.IncreasedWaterConsumption]: "Augmentation de la consommation d'eau",
    [AnimalProfileHealthObservationsDtoType.SkinCrusts]: 'Croute sur la peau',
    [AnimalProfileHealthObservationsDtoType.RedAndSwollenGums]: 'Rougeurs et gencives enflées',
    [AnimalProfileHealthObservationsDtoType.Panting]: 'Halètement',
    [AnimalProfileHealthObservationsDtoType.Sneezing]: 'Éternuement',
    [AnimalProfileHealthObservationsDtoType.NoseBleeding]: 'Saignement du nez',
    [AnimalProfileHealthObservationsDtoType.ExcessiveEyeBlinking]: 'Clignement excessifs des yeux',
    [AnimalProfileHealthObservationsDtoType.MobilityIssues]: 'Difficulté de déplacement',
    [AnimalProfileHealthObservationsDtoType.InappropriateScratching]: 'Griffes sur des endroits indésirable',
    [AnimalProfileHealthObservationsDtoType.NocturnalMeowingOrAgitation]:
      'Miaulements intempestifs ou agitation nocturne',
    [AnimalProfileHealthObservationsDtoType.InappropriateElimination]: 'Marquages, éliminations hors litière',
    [AnimalProfileHealthObservationsDtoType.FearfulBehavior]: 'Peur ou comportement craintif',

    // Dog specific issues
    [AnimalProfileHealthObservationsDtoType.ExcessiveObjectChewing]: "Mâchonnement excessif d'objets",
    [AnimalProfileHealthObservationsDtoType.ExcessiveScratching]: 'Grattages excessifs',
    [AnimalProfileHealthObservationsDtoType.UnusualFrequentUrination]:
      'Urination fréquente dans des endroits inhabituels',
    [AnimalProfileHealthObservationsDtoType.AppetiteLoss]: "Perte d'appétit",
    [AnimalProfileHealthObservationsDtoType.ExcessiveLicking]: 'Léchage excessif',
    [AnimalProfileHealthObservationsDtoType.UnexplainedAggressiveness]: 'Agressivité inexpliquée',
    [AnimalProfileHealthObservationsDtoType.SuddenWeightChange]: 'Perte ou gain soudain de poids',
    [AnimalProfileHealthObservationsDtoType.VomitingDog]: 'Vomissement',
    [AnimalProfileHealthObservationsDtoType.Diarrhea]: 'Diarrhée',
    [AnimalProfileHealthObservationsDtoType.EscapeAttempts]: "Tentatives d'évasion",
    [AnimalProfileHealthObservationsDtoType.SleepHabitsChange]: 'Changements dans les habitudes de sommeil',
    [AnimalProfileHealthObservationsDtoType.ConstantBarking]: 'Aboiements constants',
    [AnimalProfileHealthObservationsDtoType.NoBarking]: "Absence d'aboiements",
    [AnimalProfileHealthObservationsDtoType.ConstantRestlessness]: 'Agitation constante',
    [AnimalProfileHealthObservationsDtoType.Lethargy]: "Manque d'énergie, fatigue",
    [AnimalProfileHealthObservationsDtoType.RefusalToEat]: 'Refus de manger',
    [AnimalProfileHealthObservationsDtoType.HydrationRefusal]: "Manque d'hydratation ou refus s'hydrater",
    [AnimalProfileHealthObservationsDtoType.BadBreath]: 'Mauvaise haleine',
    [AnimalProfileHealthObservationsDtoType.IncreasedTerritorialMarking]: 'Marquage territorial accru',
    [AnimalProfileHealthObservationsDtoType.Itching]: 'Démangeaisons',
    [AnimalProfileHealthObservationsDtoType.SelfMutilation]: 'Automutilation',
    [AnimalProfileHealthObservationsDtoType.WalkingDifficulty]: 'Difficulté à marcher',
    [AnimalProfileHealthObservationsDtoType.PawPadsIssues]: 'Coussinets',
    [AnimalProfileHealthObservationsDtoType.SkinProblems]: 'Problème de peau',
    [AnimalProfileHealthObservationsDtoType.RedEyes]: 'Yeux rouges',
    [AnimalProfileHealthObservationsDtoType.Drooling]: 'Bave',
    [AnimalProfileHealthObservationsDtoType.SkinIrritation]: 'Irritation de la peau',
  }

  return map[issue]
}

export const getColorForHealthIssueObservationsType = (issue: AnimalProfileHealthObservationsDtoType): string => {
  const map: Record<AnimalProfileHealthObservationsDtoType, string> = {
    // Cat specific issues
    [AnimalProfileHealthObservationsDtoType.DiarrheaAndStoolIssues]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.Vomiting]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.MeowingInLitter]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.AppetiteChange]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.ExcessiveWeightGain]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.IncreasedWaterConsumption]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.SkinCrusts]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.RedAndSwollenGums]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.Panting]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.Sneezing]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.NoseBleeding]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.ExcessiveEyeBlinking]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.MobilityIssues]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.InappropriateScratching]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.NocturnalMeowingOrAgitation]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.InappropriateElimination]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.FearfulBehavior]: colors.cello,

    // Dog specific issues
    [AnimalProfileHealthObservationsDtoType.ExcessiveObjectChewing]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.ExcessiveScratching]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.UnusualFrequentUrination]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.AppetiteLoss]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.ExcessiveLicking]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.UnexplainedAggressiveness]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.SuddenWeightChange]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.VomitingDog]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.Diarrhea]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.EscapeAttempts]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.SleepHabitsChange]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.ConstantBarking]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.NoBarking]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.ConstantRestlessness]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.Lethargy]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.RefusalToEat]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.HydrationRefusal]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.BadBreath]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.IncreasedTerritorialMarking]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.Itching]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.SelfMutilation]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.WalkingDifficulty]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.PawPadsIssues]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.SkinProblems]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.RedEyes]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.Drooling]: colors.cello,
    [AnimalProfileHealthObservationsDtoType.SkinIrritation]: colors.cello,
  }

  return map[issue]
}

export const getReadableSpecies = (
  species: AnimalDtoSpecies,
  { withEmoji }: { withEmoji: boolean } = { withEmoji: false }
): string => {
  const map = {
    [AnimalDtoSpecies.Cat]: 'Chat',
    [AnimalDtoSpecies.Dog]: 'Chien',
    [AnimalDtoSpecies.Birds]: 'Oiseau',
    [AnimalDtoSpecies.GuineaPig]: 'Cochon d’Inde',
    [AnimalDtoSpecies.Hamster]: 'Hamster',
    [AnimalDtoSpecies.Mouse]: 'Souris',
    [AnimalDtoSpecies.Rat]: 'Rat',
    [AnimalDtoSpecies.Rabbit]: 'Lapin',
    [AnimalDtoSpecies.Ferret]: 'Furet',
    [AnimalDtoSpecies.Equidae]: 'Equidé',
    [AnimalDtoSpecies.Pogonas]: 'Pogana',
    [AnimalDtoSpecies.Lizard]: 'Lézard',
    [AnimalDtoSpecies.Snake]: 'Serpent',
    [AnimalDtoSpecies.Turtle]: 'Tortue',
    [AnimalDtoSpecies.Pigs]: 'Porcin',
    [AnimalDtoSpecies.Cattle]: 'Bovin',
    [AnimalDtoSpecies.Sheep]: 'Ovin',
    [AnimalDtoSpecies.Goats]: 'Caprin',
    [AnimalDtoSpecies.Poultry]: 'Volaille',
  }

  const readableSpecies = map[species]
  if (withEmoji) {
    return `${getEmojiSpecies(species)} ${readableSpecies}`
  }

  return readableSpecies
}

export const getEmojiSpecies = (field: AnimalDtoSpecies): string => {
  const map = {
    [AnimalDtoSpecies.Cat]: '🐱',
    [AnimalDtoSpecies.Dog]: '🐶',
    [AnimalDtoSpecies.Birds]: '🦉',
    [AnimalDtoSpecies.GuineaPig]: '🐹',
    [AnimalDtoSpecies.Hamster]: '🐹',
    [AnimalDtoSpecies.Mouse]: '🐹',
    [AnimalDtoSpecies.Rat]: '🐹',
    [AnimalDtoSpecies.Rabbit]: '🐰',
    [AnimalDtoSpecies.Ferret]: '🐹',
    [AnimalDtoSpecies.Equidae]: '🐴',
    [AnimalDtoSpecies.Pogonas]: '🦎',
    [AnimalDtoSpecies.Lizard]: '🦎',
    [AnimalDtoSpecies.Snake]: '🐍',
    [AnimalDtoSpecies.Turtle]: '🐢',
    [AnimalDtoSpecies.Pigs]: '🐷',
    [AnimalDtoSpecies.Cattle]: '🐮',
    [AnimalDtoSpecies.Sheep]: '🐑',
    [AnimalDtoSpecies.Goats]: '🐐',
    [AnimalDtoSpecies.Poultry]: '🐔',
  }

  return map[field]
}

export const getReadableSex = (
  sex: CreateAnimalBodyDtoSex,
  { withEmoji }: { withEmoji: boolean } = { withEmoji: false }
): string => {
  const map = {
    [CreateAnimalBodyDtoSex.Female]: 'Femelle',
    [CreateAnimalBodyDtoSex.Male]: 'Mâle',
  }

  const readableSpecies = map[sex]
  if (withEmoji) {
    return `${getEmojiAnimalSex(sex)} ${readableSpecies}`
  }

  return readableSpecies
}

export const getEmojiAnimalSex = (field: CreateAnimalBodyDtoSex): string => {
  const map = {
    [CreateAnimalBodyDtoSex.Female]: '♀',
    [CreateAnimalBodyDtoSex.Male]: '⚦',
  }

  return map[field]
}

type AnimalPropertyWithLabel = Exclude<
  keyof AnimalDto | keyof AnimalSearchItemDto,
  | 'customerId'
  | 'birthday'
  | 'havePassport'
  | 'vaccination'
  | 'responsibilities'
  | 'adoption'
  | 'health'
  | 'images'
  | 'hostedFamilyFollowUps'
  | 'adoptionStatus'
  | 'takeOver'
  | 'sterilizedOrNeutered'
  | 'cannotBeAdoptedStatus'
  | 'insights'
  | 'insightsCounters'
  | 'documents'
  | 'announce'
>

export const getReadableAnimalFieldLabel = (field: AnimalPropertyWithLabel): string => {
  const map: Record<AnimalPropertyWithLabel, string> = {
    identificationNumber: 'Identification',
    takeOverAt: 'Date de prise en charge',
    id: 'ID',
    name: 'Nom',
    sex: 'Sexe',
    lifeBeforeAssociation: 'Notes et parcours avant association',
    description: 'Caractère de l’animal',
    species: 'Espèce',
    breed: 'Type de race',
    color: 'Robe et couleur',
    weightInKgs: 'Poids (en Kg)',
    hair: 'Type de poils',
    adoptionStep: "Étape d'adoption",
    formAndActivity: 'Forme et activité',
    breedData: 'Portée et généalogie',
    characteristics: 'Caractéristiques',
    identifyingMarks: 'Signes distinctifs',
    tattoo: 'Tatouage',
    birthdate: 'Date de naissance',
    hairLength: 'Poils',
    identificationNumberLocation: 'Localisation du numéro d’identification',
    newName: 'Nouveau nom',
  }

  return map[field]
}

type AnimalHealthPropertyWithLabel = Exclude<
  keyof AnimalHealthDto,
  'sterilizedOrNeutered' | 'FIV' | 'FeLV' | 'operations'
> // Exclude sterilizedOrNeutered as it depends on the sex

export const getReadableAnimalHealthFieldLabel = (field: AnimalHealthPropertyWithLabel): string => {
  const map: Record<AnimalHealthPropertyWithLabel, string> = {
    healthHistory: 'Historique de Santé',
    hasEuropeanPassport: "Détention d'un passeport Européen",
    dogCategory: 'Chien de catégorie',
    pathologies: 'Pathologies',
    alimentation: 'Alimentation',
  }

  return map[field]
}

enum TestStatus {
  Positive = 'positive',
  Negative = 'negative',
  Unknown = 'unknown',
}

export const getReadableAnimalHealthTestStatus = (field: AnimalHealthDtoFeLV | AnimalHealthDtoFIV): string => {
  const map: Record<TestStatus, string> = {
    [TestStatus.Positive]: 'Positif',
    [TestStatus.Negative]: 'Négatif',
    [TestStatus.Unknown]: 'Ne sais pas',
  }

  return map[field]
}

export const getColorForAnimalHealthTestStatus = (field: AnimalHealthDtoFeLV | AnimalHealthDtoFIV): string => {
  const map: Record<TestStatus, string> = {
    [TestStatus.Positive]: colors.punch,
    [TestStatus.Negative]: colors.seaGreen,
    [TestStatus.Unknown]: colors.amber,
  }

  return map[field]
}

export const getReadableTakeOverOrigin = (field: AnimalTakeOverDtoOrigin): string => {
  const map: Record<AnimalTakeOverDtoOrigin, string> = {
    [AnimalTakeOverDtoOrigin.BirthInCaptivity]: 'Naissance en captivité',
    [AnimalTakeOverDtoOrigin.CaptureInTheWild]: 'Capture dans le milieu naturel',
    [AnimalTakeOverDtoOrigin.Unknown]: 'Inconnue',
  }

  return map[field]
}

export const getReadableTakeOverReason = (field: AnimalTakeOverDtoReason): string => {
  const map: Record<AnimalTakeOverDtoReason, string> = {
    [AnimalTakeOverDtoReason.AbandonmentVeterinaryClinic]: 'Cession - Clinique vétérinaire',
    [AnimalTakeOverDtoReason.AbandonmentPound]: 'Cession - Fourrière',
    [AnimalTakeOverDtoReason.DeathOfTheOwner]: 'Cession particulier (e.g. Décès)',
    [AnimalTakeOverDtoReason.Others]: 'Autres abandon',
    [AnimalTakeOverDtoReason.AbandonmentTownHall]: 'Cession - Mairie',
    [AnimalTakeOverDtoReason.AbandonmentAssociation]: 'Cession - Association',
    [AnimalTakeOverDtoReason.AbandonmentIndividual]: 'Abandon - Particulier',
    [AnimalTakeOverDtoReason.AnimalSeizure]: 'Saisie',
    [AnimalTakeOverDtoReason.Trapping]: 'Trappage',
    [AnimalTakeOverDtoReason.Birth]: 'Naissance',
  }

  return map[field]
}

export const getReadableIdentificationNumber = (identifier: string): string => {
  if (identifier.length === 15) {
    return splitOn(identifier, 3, 5, 7).join(' ')
  }

  if (identifier.length === 7) {
    return splitOn(identifier, 1, 4).join(' ')
  }

  return splitOn(identifier, 3).join(' ')
}

const splitOn: {
  <T = string>(s: T, ...i: number[]): T[]
  <T extends any[]>(s: T, ...i: number[]): T[]
} = <T>(slicable: string | T[], ...indices: number[]) => [0, ...indices].map((n, i, m) => slicable.slice(n, m[i + 1]))

export const getReadableHostedFamilyFollowUpType = (field: HostedFamilyFollowUpDtoType): string => {
  const map: Record<HostedFamilyFollowUpDtoType, string> = {
    [HostedFamilyFollowUpDtoType.InitialPlacement]: 'Placement',
    [HostedFamilyFollowUpDtoType.Shift]: 'Déplacement',
    [HostedFamilyFollowUpDtoType.ChangeOfHostedFamily]: "Changement de famille d'accueil",
    [HostedFamilyFollowUpDtoType.Exit]: 'Sortie',
  }

  return map[field]
}

export const getColorForHostedFamilyFollowUpType = (field: HostedFamilyFollowUpDtoType): string => {
  const map: Record<HostedFamilyFollowUpDtoType, string> = {
    [HostedFamilyFollowUpDtoType.InitialPlacement]: colors.mintTulip,
    [HostedFamilyFollowUpDtoType.Shift]: colors.peach,
    [HostedFamilyFollowUpDtoType.ChangeOfHostedFamily]: colors.bermudaGray,
    [HostedFamilyFollowUpDtoType.Exit]: colors.silver,
  }

  return map[field]
}

export const getReadableContactFollowUpType = (field: ContactHostedFamilyFollowUpDtoType): string => {
  const map: Record<ContactHostedFamilyFollowUpDtoType, string> = {
    [ContactHostedFamilyFollowUpDtoType.Physical]: 'Physique',
    [ContactHostedFamilyFollowUpDtoType.Phone]: 'Au téléphone/visio conférence',
  }
  return map[field]
}

export const getColorForContactFollowUpType = (field: ContactHostedFamilyFollowUpDtoType): string => {
  const map: Record<ContactHostedFamilyFollowUpDtoType, string> = {
    [ContactHostedFamilyFollowUpDtoType.Physical]: colors.mintTulip,
    [ContactHostedFamilyFollowUpDtoType.Phone]: colors.anakiwa,
  }
  return map[field]
}

export const getReadableAdministrativeFollowUpDtoType = (field: AdministrativeFollowUpDtoType): string => {
  const map: Record<AdministrativeFollowUpDtoType, string> = {
    [AdministrativeFollowUpDtoType.MakingContact]: 'Prise de contact',
    [AdministrativeFollowUpDtoType.HomeVisit]: 'Visite du domicile',
    [AdministrativeFollowUpDtoType.AnimalMeeting]: "Rencontre avec l'animal",
    [AdministrativeFollowUpDtoType.IssueOfCertificate]: 'Émission certificat',
    [AdministrativeFollowUpDtoType.ReceiptOfSignedCertificate]: 'Réception certificat signé',
    [AdministrativeFollowUpDtoType.IssueOfAdoptionContract]: `Émission du contrat d'adoption`,
    [AdministrativeFollowUpDtoType.ReceiptOfSignedContract]: 'Réception du contrat signé',
  }

  return map[field]
}

export const getColorForAdministrativeFollowUpDtoType = (field: AdministrativeFollowUpDtoType): string => {
  const map: Record<AdministrativeFollowUpDtoType, string> = {
    [AdministrativeFollowUpDtoType.MakingContact]: colors.mintTulip,
    [AdministrativeFollowUpDtoType.HomeVisit]: colors.anakiwa,
    [AdministrativeFollowUpDtoType.AnimalMeeting]: colors.anakiwa,
    [AdministrativeFollowUpDtoType.IssueOfCertificate]: colors.amber,
    [AdministrativeFollowUpDtoType.ReceiptOfSignedCertificate]: colors.cello,
    [AdministrativeFollowUpDtoType.IssueOfAdoptionContract]: colors.amber,
    [AdministrativeFollowUpDtoType.ReceiptOfSignedContract]: colors.seaGreen,
  }

  return map[field]
}

export const getReadableAnimalDocumentType = (field: AnimalDocumentDtoType): string => {
  const map: Record<AnimalDocumentDtoType, string> = {
    [AnimalDocumentDtoType.IdentificationCertificate]: "Certificat d'identification",
    [AnimalDocumentDtoType.VeterinaryCertificate]: 'Certificat Vétérinaire',
    [AnimalDocumentDtoType.AdoptionContract]: "Contrat d'adoption",
    [AnimalDocumentDtoType.CopyOfDeclarationOfBirth]: 'Copie de la déclaration de Naissance',
    [AnimalDocumentDtoType.TransferCertificate]: 'Certificat de cession',
    [AnimalDocumentDtoType.TransferInvoice]: 'Attestation de cession ou certificat de vente',
    [AnimalDocumentDtoType.HealthBooklet]: 'Carnet de santé',
    [AnimalDocumentDtoType.CertificateOfGoodHealth]: 'Certificat de bonne santé',
    [AnimalDocumentDtoType.PrimovaccinationCertificate]: 'Certificat de primovaccination',
    [AnimalDocumentDtoType.TestResult]: 'Résultat de test',
    [AnimalDocumentDtoType.InformationDocument]: "Document d'information",
    [AnimalDocumentDtoType.VeterinaryOrderForm]: 'Bon vétérinaire',
    [AnimalDocumentDtoType.SignedAdoptionCertificate]: "Certificat d'adoption signé",
    [AnimalDocumentDtoType.SignedAdoptionContract]: "Contrat d'adoption signé",
    [AnimalDocumentDtoType.Others]: 'Autres documents',
  }

  return map[field]
}

export const getColorForAnimalDocumentType = (field: AnimalDocumentDtoType): string => {
  const map: Record<AnimalDocumentDtoType, string> = {
    [AnimalDocumentDtoType.IdentificationCertificate]: colors.bermudaGray,
    [AnimalDocumentDtoType.VeterinaryCertificate]: colors.bermudaGray,
    [AnimalDocumentDtoType.AdoptionContract]: colors.bermudaGray,
    [AnimalDocumentDtoType.CopyOfDeclarationOfBirth]: colors.bermudaGray,
    [AnimalDocumentDtoType.TransferCertificate]: colors.bermudaGray,
    [AnimalDocumentDtoType.TransferInvoice]: colors.bermudaGray,
    [AnimalDocumentDtoType.HealthBooklet]: colors.bermudaGray,
    [AnimalDocumentDtoType.CertificateOfGoodHealth]: colors.bermudaGray,
    [AnimalDocumentDtoType.PrimovaccinationCertificate]: colors.bermudaGray,
    [AnimalDocumentDtoType.TestResult]: colors.bermudaGray,
    [AnimalDocumentDtoType.InformationDocument]: colors.bermudaGray,
    [AnimalDocumentDtoType.VeterinaryOrderForm]: colors.amber,
    [AnimalDocumentDtoType.SignedAdoptionCertificate]: colors.bermudaGray,
    [AnimalDocumentDtoType.SignedAdoptionContract]: colors.bermudaGray,
    [AnimalDocumentDtoType.Others]: colors.mintTulip,
  }

  return map[field]
}

export const getReadableAnimalSize = (field: AnimalFormAndActivityDtoSize): string => {
  const map: Record<AnimalFormAndActivityDtoSize, string> = {
    [AnimalFormAndActivityDtoSize.VerySmall]: 'Très petite',
    [AnimalFormAndActivityDtoSize.Small]: 'Petite',
    [AnimalFormAndActivityDtoSize.Medium]: 'Moyenne',
    [AnimalFormAndActivityDtoSize.Large]: 'Grande',
    [AnimalFormAndActivityDtoSize.VeryLarge]: 'Très grande',
  }

  return map[field]
}

export const getReadableAnimalCorpulence = (field: AnimalFormAndActivityDtoCorpulence): string => {
  const map: Record<AnimalFormAndActivityDtoCorpulence, string> = {
    [AnimalFormAndActivityDtoCorpulence.Underweight]: 'Sous-poids',
    [AnimalFormAndActivityDtoCorpulence.ABitUnderweight]: 'Maigre',
    [AnimalFormAndActivityDtoCorpulence.Balanced]: 'Equilibré(e)',
    [AnimalFormAndActivityDtoCorpulence.ABitOverweight]: 'Un peu trop gros',
    [AnimalFormAndActivityDtoCorpulence.Overweight]: 'Sur-poids',
  }

  return map[field]
}

export const getReadableAnimalActivity = (field: AnimalFormAndActivityDtoActivity): string => {
  const map: Record<AnimalFormAndActivityDtoActivity, string> = {
    [AnimalFormAndActivityDtoActivity.VeryLow]: 'Très peu actif',
    [AnimalFormAndActivityDtoActivity.Low]: 'Peu actif',
    [AnimalFormAndActivityDtoActivity.Medium]: 'Actif',
    [AnimalFormAndActivityDtoActivity.High]: 'Très actif',
  }

  return map[field]
}

export const getReadableAnimalAdoptionOfferType = (field: AnimalAdoptionDtoOfferType): string => {
  const map: Record<AnimalAdoptionDtoOfferType, string> = {
    [AnimalAdoptionDtoOfferType.Donation]: 'Don',
    [AnimalAdoptionDtoOfferType.HealthCostCompensation]: 'Compensation des frais de santé',
    [AnimalAdoptionDtoOfferType.SaleOffer]: 'Vente',
  }

  return map[field]
}

export const getReadableAdministrativeFollowUpStatus = (field: AdministrativeFollowUpDtoStatus): string => {
  const map: Record<AdministrativeFollowUpDtoStatus, string> = {
    [AdministrativeFollowUpDtoStatus.Done]: 'Fait',
    [AdministrativeFollowUpDtoStatus.OnGoing]: 'En cours',
    [AdministrativeFollowUpDtoStatus.ToPlan]: 'A planifier',
  }

  return map[field]
}

export const getReadableAnimalBreedTitle = (field: AnimalBreedDtoBreedTitle): string => {
  const map: Record<AnimalBreedDtoBreedTitle, string> = {
    [AnimalBreedDtoBreedTitle.BreedType]: 'Type de race',
    [AnimalBreedDtoBreedTitle.Purebred]: 'Race',
    [AnimalBreedDtoBreedTitle.MixedBreed]: 'Croisé',
  }

  return map[field]
}

export const getReadableAnimalDogCategory = (field: AnimalHealthDtoDogCategory): string => {
  const map: Record<AnimalHealthDtoDogCategory, string> = {
    [AnimalHealthDtoDogCategory.One]: '1',
    [AnimalHealthDtoDogCategory.Two]: '2',
    [AnimalHealthDtoDogCategory.Three]: '3',
    [AnimalHealthDtoDogCategory.Other]: 'Aucune des trois',
  }

  return map[field]
}

export const getReadableVaccinationOperationType = (field: VaccinationOperationDtoType): string => {
  const map: Record<VaccinationOperationDtoType, string> = {
    [VaccinationOperationDtoType.AnnualReminder]: 'Rappel annuel',
    [VaccinationOperationDtoType.FirstInjection]: 'Première injection',
    [VaccinationOperationDtoType.SecondInjection]: 'Deuxième injection',
    [VaccinationOperationDtoType.ThirdInjection]: 'Troisième injection',
  }

  return map[field]
}

export const getReadableVaccines = (field: AnimalVaccinationDtoVaccines): string => {
  const map: Record<AnimalVaccinationDtoVaccines, string> = {
    [AnimalVaccinationDtoVaccines.Chppi]: 'CHPPi',
    [AnimalVaccinationDtoVaccines.Clamoxydose]: 'Clamoxydose',
    [AnimalVaccinationDtoVaccines.Herpesvirose]: 'Herpesvirose',
    [AnimalVaccinationDtoVaccines.Leptospirose]: 'Leptospirose (L)',
    [AnimalVaccinationDtoVaccines.Leucose]: 'Leucose',
    [AnimalVaccinationDtoVaccines.Rage]: 'Rage',
    [AnimalVaccinationDtoVaccines.Tc]: 'TC',
  }
  return map[field]
}

export const getReadableTattooLocation = (field: TattooDtoLocation): string => {
  const map: Record<TattooDtoLocation, string> = {
    [TattooDtoLocation.LeftEar]: 'Oreille gauche',
    [TattooDtoLocation.LeftGroin]: 'Aine gauche',
    [TattooDtoLocation.RightEar]: 'Oreille droite',
    [TattooDtoLocation.RightGroin]: 'Aine droite',
    [TattooDtoLocation.Other]: 'Autre',
  }

  return map[field]
}
