import { ArrowForwardIos, InfoOutlined } from '@mui/icons-material'
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { AdoptersClient } from '../../interactors/clients/AdoptersClient'
import { AnimalsClient } from '../../interactors/clients/AnimalsClient'
import { CustomersClient } from '../../interactors/clients/CustomersClient'
import {
  AdoptionAttemptDto,
  AdoptionAttemptDtoStatus,
  AnimalDto,
  CustomerDto,
} from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { colors, lightBlue, lightRed, theme } from '../theme'
import { AccessoriesTab } from './AccessoriesTab'
import { AdoptionInfoBox } from './AdoptionInfoBox'
import { AdoptionPaymentCard } from './AdoptionPaymentCard'
import { AnimalCard } from './AnimalCard'
import { ConfirmationPopIn, PopIn } from './ConfirmationPopIn'
import { ImportDocumentCard } from './ImportDocumentCard'
import { DocumentsTab } from './DocumentsTab'
import { BrandItem } from '../Shopping/BrandItem'
import { FurtherInfo } from './FurtherInfo'

const popIn: PopIn = {
  type: 'confirm',
  title: "J'ai effectué le paiement",
  message:
    'En cliquant sur “je confirme” vous déclarez sur l’honneur avoir effectué le virement bancaire ou envoyé un chèque à l’adresse de l’assiociation.',
  cancelText: 'Annuler',
  confirmText: 'Je confirme',
}

export enum Categories {
  Documents = 'documents',
  Action = 'action',
  Fidelami = 'fidelami',
}

export const AdoptionDetailsScreen: React.FC = () => {
  const [adoptionAttempt, setAdoptionAttempt] = useState<AdoptionAttemptDto | null>(null)
  const [animal, setAnimal] = useState<AnimalDto | null>(null)
  const [association, setAssociation] = useState<CustomerDto | null>(null)
  const [file, setFile] = useState<File | null>(null)
  const [openPaymentModal, setOpenPaymentModal] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState<Categories>(Categories.Fidelami)
  const { adoptionId } = useParams() as { adoptionId: string }
  const accountsStore = useAccountsStore()
  const navigate = useNavigate()
  const globalSnackbarStore = useGlobalSnackbarStore()
  /* const navigate = useNavigate() */

  const updateAdoptionAttemptMutation = useMutation(
    async (data: { adoptionAttempt: FormData; id: string; type: 'certificate' | 'contract' | 'payment' }) => {
      return await AdoptersClient.updateAdoptionAttempt(data)
    },
    {
      onSuccess: (data) => {
        setAdoptionAttempt(data)
        const index = accountsStore.connectedAccount?.adoptionAttempts?.findIndex(
          (attempt) => attempt.id === adoptionId
        )
        if (index && accountsStore.connectedAccount && accountsStore.connectedAccount.adoptionAttempts) {
          accountsStore.connectedAccount.adoptionAttempts[index] = data
        }
      },
    }
  )

  const onSubmit = async (data: Partial<AdoptionAttemptDto>, type: 'certificate' | 'contract' | 'payment') => {
    if (!adoptionAttempt) return

    const formData = new FormData()

    if (type === 'certificate' || type === 'contract') {
      if (!file) return globalSnackbarStore.triggerErrorMessage('Veuillez ajouter un fichier')
    }

    // Append the selected file if available
    if (file) {
      formData.append('file', file)
    }

    // Append other form data
    Object.entries({
      ...adoptionAttempt,
      ...data,
    }).forEach(([key, value]) => {
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(key, value.toString())
      } else if (typeof value === 'boolean') {
        formData.append(key, value ? 'true' : 'false')
      } else if (value && typeof value === 'object') {
        formData.append(key, JSON.stringify(value))
      }
    })

    await updateAdoptionAttemptMutation.mutateAsync({ adoptionAttempt: formData, id: adoptionId, type })
  }

  const onCancel = async () => {
    setOpenPaymentModal(false)
  }

  const onConfirm = async (type?: PopIn['type']) => {
    console.log(type)
    await onSubmit({ step: 4 }, 'payment')
    setOpenPaymentModal(false)
  }

  const location = useLocation()
  const queryParams = new URLSearchParams(window.location.search)
  const handleCategoryChange = (category: Categories) => {
    setSelectedCategory(category)
    navigate(`${location.pathname}?${`page=${category}`}`)
  }

  useEffect(() => {
    const tab = queryParams.get('page') as Categories
    if (tab) {
      setSelectedCategory(tab)
    } else {
      setSelectedCategory(
        animal?.species === 'cat' || animal?.species === 'dog' ? Categories.Fidelami : Categories.Documents
      )
    }
  }, [queryParams])

  useEffect(() => {
    const fetchAdoptionAttemptAndAnimal = async () => {
      const adoptionAttempt = accountsStore.connectedAccount?.adoptionAttempts?.find(
        (attempt) => attempt.id === adoptionId
      )
      setAdoptionAttempt(adoptionAttempt || null)

      if (!adoptionAttempt) return

      const animal = await AnimalsClient.getAnimalById(adoptionAttempt.animalId)
      setAnimal(animal)

      const customer = await CustomersClient.getCurrentCustomer()
      setAssociation(customer)
    }

    fetchAdoptionAttemptAndAnimal()
  }, [])

  if (!adoptionAttempt || !animal || !association) return null

  const countActions = () => {
    let count = 0
    if (adoptionAttempt?.certificate?.original && !adoptionAttempt?.certificate?.signed) {
      count++
    }
    if (adoptionAttempt?.contract?.original && !adoptionAttempt?.contract?.signed) {
      count++
    }
    if (adoptionAttempt?.contract?.signed && !adoptionAttempt?.paymentSentByUser) {
      count++
    }

    if (adoptionAttempt?.done) {
      return 0
    }

    if (
      adoptionAttempt?.status === AdoptionAttemptDtoStatus.SuspendedByUser ||
      adoptionAttempt?.status === AdoptionAttemptDtoStatus.SuspendedByCustomer
    ) {
      return 0
    }
    return count
  }

  const displayAction = () => {
    if (
      adoptionAttempt?.status === AdoptionAttemptDtoStatus.SuspendedByUser ||
      adoptionAttempt?.status === AdoptionAttemptDtoStatus.SuspendedByCustomer
    ) {
      return (
        <Card sx={{ backgroundColor: lightRed }}>
          <CardContent>
            <Typography variant="body1" component="p" sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 400 }}>
              {`Cette adoption a été suspendue. Pour plus d’informations, contactez l’association.`}
            </Typography>
          </CardContent>
        </Card>
      )
    }

    if ((adoptionAttempt?.contract?.signed && adoptionAttempt?.paymentDone) || adoptionAttempt.done) {
      return (
        <Card sx={{ backgroundColor: lightBlue }}>
          <CardContent>
            <Typography variant="body1" component="p" sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 400 }}>
              <b>Félicitations 🎉</b>
              <br />
              {`Le versement de la contribution a bien été effectué et l'association va pouvoir valider la procédure d’adoption. Avant d’accueillir ${animal.name} bénéficiez d’offres exclusives pour encore quelques jours pour préparer son arrivée`}
            </Typography>
          </CardContent>
        </Card>
      )
    }

    if (adoptionAttempt?.certificate?.original && !adoptionAttempt?.certificate?.signed) {
      return (
        <>
          {adoptionAttempt?.certificate?.refusedReason && (
            <AdoptionInfoBox
              message={`Il y a un problème avec le certficat que vous avez transmis : "${adoptionAttempt.certificate.refusedReason}". Merci de corriger l'erreur et de renvoyer le certificat signé.`}
              type="error"
            />
          )}
          <ImportDocumentCard
            setFile={setFile}
            originalFileKey={adoptionAttempt?.certificate?.original}
            onSubmit={onSubmit}
            filetype="certificate"
            buttonsShouldBeDisabled={adoptionAttempt?.certificate?.original === 'NOT_PROVIDED.pdf'}
          />
        </>
      )
    }
    if (adoptionAttempt?.contract?.original && !adoptionAttempt?.contract?.signed) {
      return (
        <>
          {adoptionAttempt?.contract?.refusedReason && (
            <AdoptionInfoBox
              message={`Il y a un problème avec le contrat que vous avez transmis : "${adoptionAttempt.contract.refusedReason}". Merci de corriger l'erreur et de renvoyer le contrat signé.`}
              type="error"
            />
          )}

          <ImportDocumentCard
            setFile={setFile}
            buttonsShouldBeDisabled={adoptionAttempt?.contract?.original === 'NOT_PROVIDED.pdf'}
            originalFileKey={adoptionAttempt?.contract?.original}
            onSubmit={onSubmit}
            filetype="contract"
          />
        </>
      )
    }

    if (adoptionAttempt?.contract?.signed && !adoptionAttempt?.paymentSentByUser && !adoptionAttempt?.paymentDone) {
      return (
        <AdoptionPaymentCard
          animal={animal}
          association={association}
          attemptId={adoptionAttempt.id}
          setOpenPaymentModal={setOpenPaymentModal}
        />
      )
    }

    if (adoptionAttempt?.contract?.signed && adoptionAttempt?.paymentSentByUser && !adoptionAttempt?.paymentDone) {
      return (
        <Card sx={{ backgroundColor: lightBlue }}>
          <CardContent>
            <Typography variant="body1" component="p" sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 400 }}>
              {`La réception du paiement est en cours de confirmation par l’association. En attendant la validation, préparez l’arrivée de ${animal.name} !`}
            </Typography>
          </CardContent>
        </Card>
      )
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 2, my: 2 }}>
        <Card sx={{ backgroundColor: lightBlue }}>
          <CardContent>
            <Typography variant="body1" component="p" sx={{ fontSize: '14px', lineHeight: '19.2px', fontWeight: 400 }}>
              {`Le certificat d’engagement rappelle qu’un budget annuel de 50 à 200 € est nécessaire pour assurer les soins courants d’un animal en bonne santé.`}
              <br />
              {`Avec Kozoo, estimez gratuitement le coût d’une assurance personnalisable pour couvrir la santé de ${animal.name}`}
            </Typography>
          </CardContent>
        </Card>
        <BrandItem
          animalProfile={animal}
          title="Assurance Kozoo"
          description={`Remboursement de 50% à 100%\n
        Plafond de 1000€ à 3000€`}
          logoUrl="https://www.kozoo.eu/wp-content/themes/kozoo/assets/images/kozoo-assurance-chien-chat-digitale.svg"
          imgSrc="https://animals-photos.s3.amazonaws.com/2023-04-05+(1).jpg"
          brandName="kozoo"
          catchPhrase="Dès 5,44€/mois"
          redirectUrl="https://bit.ly/4a6FFAJ"
        />
      </Box>
    )
  }

  const displayActionText = (): { title?: string; text: string } => {
    if (
      adoptionAttempt?.status === AdoptionAttemptDtoStatus.SuspendedByUser ||
      adoptionAttempt?.status === AdoptionAttemptDtoStatus.SuspendedByCustomer
    ) {
      return { title: `Cette adoption a été suspendue.`, text: `Pour plus d’informations, contactez l’association.` }
    }

    if ((adoptionAttempt?.contract?.signed && adoptionAttempt?.paymentDone) || adoptionAttempt.done) {
      return {
        title: 'Félicitations 🎉',
        text: `Le versement de la contribution a bien été effectué et l'association va pouvoir valider la procédure d’adoption.`,
      }
    }

    if (adoptionAttempt?.certificate?.original && !adoptionAttempt?.certificate?.signed) {
      return { text: `Certificat d'engagement à signer` }
    }
    if (adoptionAttempt?.contract?.original && !adoptionAttempt?.contract?.signed) {
      return { text: `Contrat d'adoption à signer` }
    }

    if (adoptionAttempt?.contract?.signed && !adoptionAttempt?.paymentSentByUser && !adoptionAttempt?.paymentDone) {
      return { text: `Réglement de la contribution` }
    }

    if (adoptionAttempt?.contract?.signed && adoptionAttempt?.paymentSentByUser && !adoptionAttempt?.paymentDone) {
      return { text: `La réception du paiement est en cours de confirmation par l’association.` }
    }

    return { title: 'Une action facultative:', text: `Obtenir un devis d'assurance gratuit pour ${animal.name}` }
  }

  return (
    <Box sx={{ padding: { xs: 0, md: 2 }, minHeight: '100%', pb: 20 }}>
      <Box sx={{ maxWidth: '1200px', marginX: 'auto', mb: 10, p: 2 }}>
        <Grid container spacing={2} sx={{ pb: 4 }}>
          <Grid item xs={12} md={3.5}>
            <AnimalCard animal={animal} association={association} />
          </Grid>
          <Grid item xs={12} md={8.5}>
            {selectedCategory === Categories.Action ? (
              <>
                <Typography variant="h2" sx={{ my: 2 }}>
                  Action(s) à mener
                </Typography>
                {displayAction()}
              </>
            ) : selectedCategory === Categories.Documents ? (
              <>
                <FurtherInfo animal={animal} />
                <Typography variant="h2" sx={{ my: 2 }}>
                  Documents
                </Typography>
                <DocumentsTab attempt={adoptionAttempt} animal={animal} />
              </>
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '12px',
                    mb: 3,
                    mt: { xs: 1, md: 0 },
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: colors.mintTulip,
                      color: 'black',
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      boxShadow: 'none',
                      borderRadius: '12px',
                      '&:hover': {
                        backgroundColor: colors.blueSky,
                      },
                      '&:disabled': {
                        py: '13px',
                        border: 'none',
                        backgroundColor: 'rgba(243, 243, 245, 1)',
                        color: '#696B6D',
                      },
                    }}
                    onClick={() => handleCategoryChange(Categories.Documents)}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <InfoOutlined sx={{ color: 'black' }} />
                      <Typography>Informations et documents de {animal.name}</Typography>
                    </Box>

                    <ArrowForwardIos sx={{ color: 'black' }} />
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: theme.palette.secondary.main,
                      color: 'white',
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      boxShadow: 'none',
                      borderRadius: '12px',
                      '&:hover': {
                        backgroundColor: theme.palette.secondary.dark,
                      },
                      '&:disabled': {
                        py: '13px',
                        border: 'none',
                        backgroundColor: 'rgba(243, 243, 245, 1)',
                        color: '#696B6D',
                      },
                    }}
                    onClick={() => handleCategoryChange(Categories.Action)}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'left',
                        textAlign: 'left',
                      }}
                    >
                      <Typography>{displayActionText().title ?? `${countActions()} action(s) à mener`}</Typography>
                      <Typography>{displayActionText().text}</Typography>
                    </Box>
                    <ArrowForwardIos sx={{ color: 'white' }} />
                  </Button>
                </Box>
                <AccessoriesTab animalProfile={animal} />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    mt: 2,
                    p: 1,
                    px: 2,
                    backgroundColor: colors.mintTulip,
                    mx: -2,
                  }}
                >
                  <Typography sx={{ fontSize: '14px' }}>
                    {`Sélectionnée par la SPA et Petso, Fidelami est une marque dédiée au bien-être animal et à l'adoption responsable. Leurs produits, conçus avec soin, répondent aux besoin de nos compagnons à quatres pattes.`}{' '}
                    <b>{`En achetant Fidelami, vous offrez aussi des repas en refuge.`}</b>
                  </Typography>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
        <ConfirmationPopIn popIn={popIn} isModalOpened={openPaymentModal} onConfirm={onConfirm} onCancel={onCancel} />
      </Box>
    </Box>
  )
}
