import { MetricalpReactProvider } from '@metricalp/react'
import { Box, CssBaseline, ThemeProvider } from '@mui/material'
import { FC, useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Route, Routes } from 'react-router-dom'
import { AuthService } from './interactors/services/AuthService'
import { useGlobalLoaderStore } from './store/GlobalLoaderStore'
import { LoginPage } from './views/Login/LoginPage'
import { PATHS } from './views/PATHS'
import { CircularProgressPanel } from './views/common/CircularProgressPanel'
import { theme } from './views/theme'
import { PrivateRoutes } from './views/utils/PrivateRoutes'

import { Navigate } from 'react-router-dom'
import { useAccountsStore } from './store/AccountsStore'
import { findHome } from './utils/findHomeForUser'
import { useFirebaseMessaging } from './utils/hooks/useFirebaseMessaging'
import { AdoptionDetailsScreen } from './views/Adoptions/AdoptionDetailsScreen'
import { AdoptionsListScreen } from './views/Adoptions/AdoptionsListScreen'
import { SendMyBeReal } from './views/AnimalDailyPics/SendMyBeReal'
import { TikTokView } from './views/AnimalDailyPics/TikTokView'
import { AddAnimalDocumentScreen } from './views/Animals/AddAnimalDocumentScreen'
import { AddAnimalObservationScreen } from './views/Animals/AddAnimalObservationScreen'
import { AddAnimalOperationScreen } from './views/Animals/AddAnimalOperationScreen'
import { AddAnimalScreen } from './views/Animals/AddAnimalScreen'
import { AnimalDetailsScreen } from './views/Animals/AnimalDetailsScreen'
import { AnimalsListScreen } from './views/Animals/AnimalsListScreen'
import { ObservationDetailsScreen } from './views/Animals/ObservationDetailsScreen'
import { OperationDetailsScreen } from './views/Animals/OperationDetailsScreen'
import { ConversationsScreen } from './views/Chat/ConversationsScreen'
import { AdoptionFormScreen } from './views/Dashboard/AdoptionFormScreen'
import { ProfilScreen } from './views/Dashboard/ProfilScreen'
import { ProfileDetailsScreen } from './views/Dashboard/ProfileDetailsScreen'
import AuthRedirect from './views/Login/AuthRedirect'
import { PetParentsListScreen } from './views/PetParents/PetParentsListScreen'
import { CreatePasswordPage } from './views/Register/CreatePasswordPage'
import { RequestResetPasswordPage } from './views/ResetPassword/RequestResetPasswordPage'
import { ResetPasswordPage } from './views/ResetPassword/ResetPasswordPage'
import { ServiceLocationScreen } from './views/ServicesMap/ServiceLocationScreen'
import { ServicesMapScreen } from './views/ServicesMap/ServicesMapScreen'
import { BrandPage } from './views/Shopping/BrandPage'
import { ProductPage } from './views/Shopping/ProductPage'
import { ShoppingBasket } from './views/Shopping/ShoppingBasket'
import { WelcomePack } from './views/Shopping/WelcomePack'
import { GlobalSnackBar } from './views/common/GlobalSnackbar'
import NotificationModal from './views/NotificationsModal'
import { AnimalDetails } from './views/SuiviVeto/AnimalDetails'
import { Layout } from './views/SuiviVeto/Layout'
import { SuiviOperationDetailsScreen } from './views/SuiviVeto/OperationDetailsScreen'
import { SuiviObservationDetailsScreen } from './views/SuiviVeto/ObservationDetailsScreen'
const queryClient = new QueryClient()

const App: FC = () => {
  const membersStore = useAccountsStore()
  const { isLoading, setIsLoading } = useGlobalLoaderStore()

  useFirebaseMessaging()

  useEffect(() => {
    AuthService.tryLogInWithLocalToken()
      .then((account) => {
        if (account) {
          membersStore.changeConnectedAccount(account)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  /*   useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    setVh() // Set on initial load

    window.addEventListener('resize', setVh) // Set on resize

    // Cleanup function
    return () => {
      window.removeEventListener('resize', setVh) // Remove event listener on unmount
    }
  }, []) // Empty dependency array so effect only runs on mount and unmount */

  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <CircularProgressPanel />
      </ThemeProvider>
    )
  }

  document.addEventListener('DOMContentLoaded', function () {
    const testElement = document.createElement('div')

    // Apply a dvh value to the test element
    testElement.style.height = '100dvh'

    // Append to body to compute style
    document.body.appendChild(testElement)

    // Check if the height is set correctly (larger than 0 and in a reasonable range)
    if (testElement.offsetHeight > 0 && testElement.offsetHeight !== window.innerHeight) {
      // If dvh is supported and works as expected
      document.documentElement.style.setProperty('--viewport-height', '100dvh')
    }

    // Clean up by removing the test element from the DOM
    document.body.removeChild(testElement)
  })

  return (
    <>
      <MetricalpReactProvider
        initialSharedCustomProps={{
          screen_view: {
            adopterId: membersStore.connectedAccount?.id ?? 'not-connected',
            adopterName: membersStore?.connectedAccount?.firstName
              ? `${membersStore?.connectedAccount?.firstName} ${membersStore?.connectedAccount?.lastName}`
              : 'not-connected',
          },
        }}
        tid="mam84"
      >
        <CssBaseline />

        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'var(--viewport-height)',
                maxHeight: 'var(--viewport-height)',
                overflowY: 'hidden',
              }}
            >
              <Routes>
                <Route path="/" element={<PrivateRoutes />}>
                  <Route index element={<Navigate to={findHome(membersStore.connectedAccount)} />} />

                  <Route path={PATHS.adoptions.relative}>
                    <Route index element={<AdoptionsListScreen />} />
                    <Route path={PATHS.adoptionDetails.relative} element={<AdoptionDetailsScreen />} />
                    <Route path={PATHS.adoptionWelcomePack.relative} element={<WelcomePack />} />
                  </Route>

                  <Route path={PATHS.animaux.relative}>
                    <Route index element={<AnimalsListScreen />} />
                    <Route path={PATHS.ajouterAnimal.relative} element={<AddAnimalScreen />} />
                    <Route path={PATHS.animalDetails.relative} element={<AnimalDetailsScreen />} />
                    <Route path={PATHS.ajouterOperation.relative} element={<AddAnimalOperationScreen />} />
                    <Route path={PATHS.animalOperationDetails.relative} element={<OperationDetailsScreen />} />
                    <Route path={PATHS.ajouterObservation.relative} element={<AddAnimalObservationScreen />} />
                    <Route path={PATHS.animalObservationDetails.relative} element={<ObservationDetailsScreen />} />
                    <Route path={PATHS.ajouterDocument.relative} element={<AddAnimalDocumentScreen />} />
                    <Route path={PATHS.animalWelcomePack.relative} element={<WelcomePack />} />
                  </Route>

                  <Route path={PATHS.partenaires.relative}>
                    <Route index />
                    <Route path={PATHS.partenaireDetails.relative} element={<BrandPage />} />
                  </Route>

                  {/* <Route path={PATHS.panier.relative}>
                  <Route index />
                  <Route path={PATHS.recapitulatif.relative} element={<OrderSummary />} />
                </Route> */}

                  <Route path={PATHS.chat.relative}>
                    <Route index element={<ConversationsScreen />} />
                  </Route>

                  <Route path={PATHS.actualites.relative}>
                    <Route index element={<TikTokView />} />
                    <Route path={PATHS.ajouterActualite.relative} element={<SendMyBeReal />} />
                  </Route>

                  <Route path={PATHS.produits.relative}>
                    <Route index element={<TikTokView />} />
                    <Route path={PATHS.produitDetails.relative} element={<ProductPage />} />
                  </Route>

                  <Route path={PATHS.panier.relative}>
                    <Route index element={<ShoppingBasket />} />
                  </Route>

                  <Route path={PATHS['pet-parents'].relative}>
                    <Route index element={<PetParentsListScreen />} />
                  </Route>

                  <Route path={PATHS.services.relative}>
                    <Route index element={<ServicesMapScreen />} />
                    <Route path={PATHS.servicesDetails.relative} element={<ServiceLocationScreen />} />
                  </Route>

                  <Route path={PATHS.profil.relative}>
                    <Route index element={<ProfilScreen />} />
                    <Route path={PATHS.monProfil.relative} element={<ProfileDetailsScreen />} />
                    <Route path={PATHS.formulaire.relative} element={<AdoptionFormScreen />} />
                  </Route>
                </Route>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/google-callback" element={<AuthRedirect />} />
                <Route path={PATHS.createPassword.absolute} element={<CreatePasswordPage />} />
                <Route path={PATHS.resetPassword.absolute} element={<ResetPasswordPage />} />
                <Route path={PATHS.requestPasswordReset.absolute} element={<RequestResetPasswordPage />} />
                <Route path={PATHS.suivi.absolute} element={<Layout />}>
                  <Route path={PATHS.suiviAnimal.absolute} element={<AnimalDetails />} />
                  <Route path={PATHS.suiviOperationDetails.relative} element={<SuiviOperationDetailsScreen />} />
                  <Route path={PATHS.suiviObservationDetails.relative} element={<SuiviObservationDetailsScreen />} />
                </Route>
              </Routes>
            </Box>

            <GlobalSnackBar />
            <NotificationModal />
          </QueryClientProvider>
        </ThemeProvider>
      </MetricalpReactProvider>
    </>
  )
}

// window.onerror = function (message, url, lineNumber, columnNumber, error) {
//   ErrorsClient.notifyError({ message, url, lineNumber, currentUrl: window.location.href, error }).then(() => {
//     return true
//   })
// }

/* window.addEventListener('error', (event) => {
  ErrorsClient.notifyError({
    message: event.message,
    currentUrl: window.location.href,
    error: event.error.toString(),
    stack: event.error.stack,
  }).then(() => {
    return true
  })
}) */
export default App
