import { Avatar, Box, Button, FormControl, TextField, Typography } from '@mui/material'
import { Elements } from '@stripe/react-stripe-js'
import { Appearance, StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { AdoptersClient } from '../../interactors/clients/AdoptersClient'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'
import { backendClient } from '../../interactors/clients/client'
import { AdopterDto, AnimalDto, AnimalProfileDto } from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { useBasketStore } from '../../store/BasketStore'
import { findGoodAnimalProfileURL } from '../../utils/findGoodAnimalProfilURL'
import { useFormExtended } from '../../utils/hooks/useFormExtended'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { CheckoutForm } from '../common/StripeCheckoutForm'
import { lightBlue, theme } from '../theme'
import { BasketItemComponent } from './BasketItem'
import { AnimalsClient } from '../../interactors/clients/AnimalsClient'

interface FormParams {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  address: string
  postalCode: string
  city: string
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string)

const FREE_DELIVERY_REFERENCES: string[] = [
  'FC01',
  'FC02',
  'FC03',
  'FC05',
  'FC06',
  'FC07',
  'FC08',
  'FD01',
  'FD02',
  'FD03',
  'FD04',
  'ABO01',
  'ABO02',
]

export const ShoppingBasket: React.FC = () => {
  const [animals, setAnimals] = useState<AnimalProfileDto[]>([])
  const [animalsInAdoption, setAnimalsInAdoption] = useState<AnimalDto[]>([])
  const [step, setStep] = useState(1)
  const [clientSecret, setClientSecret] = useState<string>('')
  const navigate = useNavigate()
  const basketStore = useBasketStore()
  const basket = basketStore.basket

  useEffect(() => {
    const fetchAnimalProfiles = async () => {
      const animalProfiles = await AnimalProfilesClient.getAllAnimalProfilesByAdopterId()
      setAnimals(animalProfiles)
    }
    const fetchAnimalsInAdoption = async () => {
      const idsOfAnimalsInAdoption = basket
        .filter((item) => item.animalId.startsWith('P-'))
        .map((item) => item.animalId)
        .filter((value, index, self) => self.indexOf(value) === index)

      if (idsOfAnimalsInAdoption.length === 0) return
      const animalsInAdoption = await Promise.all(idsOfAnimalsInAdoption.map((id) => AnimalsClient.getAnimalById(id)))
      setAnimalsInAdoption(animalsInAdoption)
    }

    fetchAnimalsInAdoption()
    fetchAnimalProfiles()
  }, [])

  const accountsStore = useAccountsStore()
  const currentAccount = accountsStore.connectedAccount

  const getDefaultValues = (account: AdopterDto | null): FormParams => ({
    firstName: account?.firstName || '',
    lastName: account?.lastName || '',
    email: account?.email || '',
    phoneNumber: account?.phoneNumber || '',
    address: account?.address || '',
    postalCode: account?.postalCode || '',
    city: account?.city || '',
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { isDirty },
  } = useFormExtended(currentAccount, getDefaultValues)

  const createPaymentIntent = async () => {
    const response = await backendClient.post('/adopters-platform/stripe/create-payment-intent', {
      items: basket.map((item) => ({
        ...item,
        price: item.price * 100,
      })),
    })
    setClientSecret(response.data)
  }

  const onSubmit = async (data: FormParams) => {
    if (!currentAccount) return
    if (isDirty) {
      const updatedAccount = {
        ...currentAccount,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        address: data.address,
        postalCode: data.postalCode,
        city: data.city,
      }
      await AdoptersClient.editAccount(updatedAccount)
    }

    createPaymentIntent()
  }

  const appearance: Appearance = {
    theme: 'stripe',
  }

  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  }

  const fdp =
    basketStore.totalItems() > 0
      ? basket.some((item) => FREE_DELIVERY_REFERENCES.includes(item.reference)) || basketStore.totalPrice() > 30
        ? 0
        : 5.99
      : 0

  if (!animals) return <CircularProgressPanel />

  return (
    <Box sx={{ padding: { xs: 0, md: 2 }, minHeight: '100%', display: 'flex' }}>
      <Box
        sx={{
          maxWidth: '1200px',
          width: '100%',
          marginX: 'auto',
          mb: 10,
          p: 2,
          pt: 2,
          pb: 0,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        {clientSecret ? (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm
              price={Math.round((basketStore.totalPrice() + fdp + Number.EPSILON) * 100) / 100}
              fromBasket
            />
          </Elements>
        ) : step < 2 ? (
          <>
            {animals.map((animal) => (
              <Box
                key={animal.id}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  borderRadius: '12px',
                  width: '100%',
                  cursor: basket.filter((item) => item.animalId === animal.id).length === 0 ? 'pointer' : 'default',
                }}
                onClick={() =>
                  basket.filter((item) => item.animalId === animal.id).length === 0 &&
                  navigate(`/mes-animaux/${animal.id}?tab=shopping`)
                }
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: lightBlue,
                    borderRadius: '12px',
                    pb: 1,
                    pr: 2,
                    gap: '8px',
                    width: 'min-content',
                    mb: -2,
                    zIndex: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate(`/mes-animaux/${animal.id}?tab=shopping`)}
                  >
                    <Avatar
                      src={animal.images.profileImageKey ? findGoodAnimalProfileURL(animal.images.profileImageKey) : ''}
                      sx={{ width: '32px', height: '32px' }}
                    />
                    <Typography sx={{ fontSize: '16px', fontWeight: 600, whiteSpace: 'nowrap' }}>
                      {animal.name}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: lightBlue,
                    borderRadius: '12px',
                    pt: 2,
                    gap: '8px',
                    width: '100%',
                  }}
                >
                  {basket.map((item) => {
                    console.log(item.animalId, animal.id)
                    if (item.animalId === animal.id) {
                      return <BasketItemComponent key={item.reference} item={item} />
                    }
                  })}
                  {basket.filter((item) => item.animalId === animal.id).length === 0 && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mb: 2 }}>
                      <Typography
                        sx={{ fontSize: '14px', fontWeight: 400, whiteSpace: 'nowrap' }}
                      >{`Voir les produits pour ${animal.name}`}</Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
            {animalsInAdoption.map((animal) => (
              <Box
                key={animal.id}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  borderRadius: '12px',
                  width: '100%',
                  cursor: basket.filter((item) => item.animalId === animal.id).length === 0 ? 'pointer' : 'default',
                }}
                onClick={() =>
                  basket.filter((item) => item.animalId === animal.id).length === 0 &&
                  navigate(
                    `/adoptions/${currentAccount?.adoptionAttempts?.find(
                      (attempt) => attempt.animalId === animal.id
                    )}?shopping=true`
                  )
                }
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: lightBlue,
                    borderRadius: '12px',
                    pb: 1,
                    pr: 2,
                    gap: '8px',
                    width: 'min-content',
                    mb: -2,
                    zIndex: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      navigate(
                        `/adoptions/${
                          currentAccount?.adoptionAttempts?.find((attempt) => attempt.animalId === animal.id)?.id
                        }?shopping=true`
                      )
                    }
                  >
                    <Avatar
                      src={animal.images.profileImageKey ? findGoodAnimalProfileURL(animal.images.profileImageKey) : ''}
                      sx={{ width: '32px', height: '32px' }}
                    />
                    <Typography sx={{ fontSize: '16px', fontWeight: 600, whiteSpace: 'nowrap' }}>
                      {animal.name}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: lightBlue,
                    borderRadius: '12px',
                    pt: 2,
                    gap: '8px',
                    width: '100%',
                  }}
                >
                  {basket.map((item) => {
                    console.log(item.animalId, animal.id)
                    if (item.animalId === animal.id) {
                      return <BasketItemComponent key={item.reference} item={item} />
                    }
                  })}
                  {basket.filter((item) => item.animalId === animal.id).length === 0 && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mb: 2 }}>
                      <Typography
                        sx={{ fontSize: '14px', fontWeight: 400, whiteSpace: 'nowrap' }}
                      >{`Vous n'avez rien pris pour ${animal.name}`}</Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
            <Typography variant="body2" sx={{ textAlign: 'center', mt: 2 }}>
              Expedié en 48h en France métropolitaine
            </Typography>
            <div style={{ height: '118px' }}></div>
            <Box
              sx={{
                position: 'fixed',
                bottom: 0,
                left: { xs: 0, md: '240px' },
                right: 0,
                backgroundColor: 'white',
                p: 4,
                px: 3,
                pt: 3,
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
                alignItems: 'center',
                borderTop: '1px solid #E0E0E0',
                boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.10)',
                zIndex: 2,
              }}
            >
              <Box
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left', gap: 1 }}
              >
                {basketStore.totalItems() > 0 && (
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{ fontSize: '14px', lineHeight: '17.2px', fontWeight: 700, color: 'black', textAlign: 'left' }}
                  >
                    {fdp > 0 ? 'Frais de port: 5.99€' : 'Livraison offerte'}
                  </Typography>
                )}
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 700, color: 'black', textAlign: 'left' }}
                >
                  {`Total TTC : ${Math.round((basketStore.totalPrice() + fdp + Number.EPSILON) * 100) / 100}€`}
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  px: '20px',
                  py: '13px',
                  borderRadius: '200px',
                  color: theme.palette.secondary.main,
                  fontSize: '16px',
                  lineHeight: '19.2px',
                  fontWeight: 600,
                  boxShadow: 'none',
                }}
                onClick={() => setStep(2)}
              >
                {`Valider ma commande`}
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography sx={{ fontWeight: 700 }}>Vérifiez vos informations personnelles</Typography>

            <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <TextField label="Prénom" variant="outlined" {...register('firstName')} placeholder="Paul" />
              <TextField label="Nom" variant="outlined" {...register('lastName')} placeholder="Lelong" />
              <TextField
                label="Email"
                variant="outlined"
                {...register('email')}
                placeholder="paul.lelong@gmail.com"
                disabled
              />
              <FormControl variant="outlined" sx={{ width: '100%' }} required>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    validate: (value: string | undefined) => {
                      console.log(value)
                      return !value || value.trim() === '' || matchIsValidTel(value) || value.trim() === '+33'
                        ? true
                        : 'Tel is invalid'
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <MuiTelInput
                      {...field}
                      aria-label="Numero de Téléphone"
                      label="Téléphone"
                      defaultCountry="FR"
                      helperText={fieldState.error ? "Le numero de téléphone n'est pas valide" : ''}
                      error={!!fieldState.error}
                      required
                    />
                  )}
                />
              </FormControl>
              <TextField
                label="Adresse"
                variant="outlined"
                {...register('address')}
                placeholder="12, impasse de la loutre"
              />
              <TextField label="Code Postal" variant="outlined" {...register('postalCode')} placeholder="34210" />
              <TextField label="Ville" variant="outlined" {...register('city')} placeholder="Aigues-Vives" />

              <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '12px', mt: 1 }}>
                <Button variant="contained" color="primary" type="button" onClick={() => setStep((prev) => prev - 1)}>
                  Précédent
                </Button>
                <Button variant="contained" color="secondary" type="submit">
                  Commander & Payer
                </Button>
              </Box>
            </form>
          </>
        )}
      </Box>
    </Box>
  )
}
