import { AdopterDto, LoginBodyDto, LoginResponseDto } from '../gen/backendClient'
import { backendClient } from './client'
import { AES } from 'crypto-ts'
import { AxiosRequestConfig } from 'axios'

export class LocalStorageToken {
  private static TOKEN_KEY = 'token'

  public static getCurrentToken(): string | null {
    return localStorage.getItem(this.TOKEN_KEY)
  }

  public static setToken(token: string): void {
    localStorage.setItem(this.TOKEN_KEY, token)
  }

  public static removeStaticToken(): void {
    localStorage.removeItem(this.TOKEN_KEY)
  }
}

export class AuthClient {
  public static async getAccessToken(email: string, password: string): Promise<string> {
    const encryptedPassword = AES.encrypt(password, 'ff276a4457e543b3db78f1c1462df89269b42c74eb7abe82').toString()

    const config: AxiosRequestConfig<LoginBodyDto> = {
      method: 'POST',
      url: '/adopters-platform/auth/login',
      data: {
        email,
        password: encryptedPassword,
      },
    }

    const response = await backendClient<LoginResponseDto>(config)

    const token = response.data.access_token
    if (!token) {
      throw new Error('No token in the response')
    }

    return token
  }

  public static async register(email: string, password: string, fullname: string): Promise<LoginResponseDto> {
    const encryptedPassword = AES.encrypt(password, 'ff276a4457e543b3db78f1c1462df89269b42c74eb7abe82').toString()
    const firstName = fullname.split(' ')[0]
    const lastName = fullname.split(' ').slice(1).join(' ')

    const response = await backendClient.post<LoginResponseDto>('/adopters-platform/auth/register', {
      email,
      password: encryptedPassword,
      firstName,
      lastName,
    })

    return response.data
  }

  public static async getGoogleAccessToken(search: string): Promise<string> {
    const response = await backendClient<LoginResponseDto>('/adopters-platform/auth/google/callback' + search)

    const token = response.data.access_token
    if (!token) {
      throw new Error('No token in the response')
    }

    return token
  }

  public static async getCurrentAccount(): Promise<AdopterDto> {
    const response = await backendClient.get<AdopterDto>('/adopters-platform/accounts/profile')

    return response.data
  }

  public static requestPasswordReset(email: string): Promise<void> {
    return backendClient.post('/adopters-platform/auth/reset-password/request', { email })
  }

  public static async resetPassword(token: string, newPassword: string): Promise<string> {
    const result = await backendClient.post<string>('/adopters-platform/auth/reset-password', { token, newPassword })

    return result.data
  }

  public static updatePassword(newPassword: string): Promise<void> {
    return backendClient.post('/adopters-platform/auth/update-password', { newPassword })
  }

  public static async createPassword(token: string, newPassword: string): Promise<string> {
    const result = await backendClient.post<string>('/adopters-platform/auth/create-password', { token, newPassword })

    return result.data
  }
}
