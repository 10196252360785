import { Box, Button, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import ReactImageMagnify from 'react-image-magnify'
import { useNavigate, useParams } from 'react-router-dom'
import Slider from 'react-slick'
import { useSwipeable } from 'react-swipeable'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'
import { AnimalProfileDto } from '../../interactors/gen/backendClient'
import { useBasketStore } from '../../store/BasketStore'
import { getProductImageSrc } from '../../utils/S3-links'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { ControlledSelectField } from '../common/ControlledSelectField'
import { theme } from '../theme'
import { ExpandableCard } from './ExpandableCard'
import data from './data/products.json'

function ScrollToTopOnMount() {
  useEffect(() => {
    const chatContainer = document.querySelector('.scrollable-content') as HTMLElement
    chatContainer.scrollTo({ top: 0 })
  }, [])

  return null
}

export const ProductPage: React.FC = () => {
  const { reference } = useParams()
  const queryParams = new URLSearchParams(window.location.search)
  const animalId = queryParams.get('animalId')
  const product = data.products.find((p) => p.reference === reference)

  const [animal, setAnimal] = React.useState<AnimalProfileDto | null>(null)

  const dynamicDescription = product?.description.replaceAll('votre animal', animal?.name || 'votre animal')
  const splittedDescription = dynamicDescription?.split('\n')

  useEffect(() => {
    const fetchAnimal = async () => {
      if (!animalId) return
      const response = await AnimalProfilesClient.getAnimalById(animalId)
      setAnimal(response)
    }
    fetchAnimal()
  }, [animalId])

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      quantity: product && product.quantities ? product.quantities[0] : 1,
      color: '',
    },
  })

  const watchColor = watch('color')
  let sliderRef: Slider | null = null

  useEffect(() => {
    switch (watchColor) {
      case 'Tahia - Rose':
        return sliderRef?.slickGoTo(1)
      case 'Lilie - Violet':
        return sliderRef?.slickGoTo(2)
      case 'Paddy - Vert':
        return sliderRef?.slickGoTo(3)
    }
  }, [watchColor])

  const settings = {
    dots: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: product?.images && product?.images.length > 1,
    initialSlide: 0,
    speed: 200,
    arrows: false,
    customPaging: () => <div className="ft-slick__dots--custom"></div>,
  }

  const basketStore = useBasketStore()
  const handleAddToCart = (data: { quantity: number; color: string | null }) => {
    if (!product) return
    basketStore.addOrUpdateItem({
      reference: product.reference,
      quantity: data.quantity,
      price: product.subscription ? product.price * 12 : product.price,
      color: data.color || '',
      animalId: animalId || '',
    })
    window.navigator.vibrate(100)
    navigate(-1)
  }

  const navigate = useNavigate()
  const nextProduct = () => {
    const currentIndex = data.products.findIndex((p) => p.reference === reference)
    const nextIndex = (currentIndex + 1) % data.products.length
    navigate(`/produits/${data.products[nextIndex].reference}?animalId=${animalId}`)
  }

  const previousProduct = () => {
    const currentIndex = data.products.findIndex((p) => p.reference === reference)
    const previousIndex = (currentIndex - 1 + data.products.length) % data.products.length
    navigate(`/produits/${data.products[previousIndex].reference}?animalId=${animalId}`)
  }

  const handlers = useSwipeable({
    onSwipedRight: () => previousProduct(),
    onSwipedLeft: () => nextProduct(),
    trackMouse: true,
    preventScrollOnSwipe: true,
  })

  if (!product) return <CircularProgressPanel />

  const hasCharacteristics =
    product.dimensions || product.contenance || product.composition || product.color || product.length

  return (
    <>
      <ScrollToTopOnMount />
      <Box sx={{ padding: { xs: 0, md: 2 }, minHeight: '100%', display: 'flex' }}>
        <Box
          sx={{
            maxWidth: '1200px',
            width: '100%',
            marginX: 'auto',
            mb: 10,
            p: 4,
            pt: 2,
            pb: 0,
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <Slider
            ref={(slider) => {
              sliderRef = slider
            }}
            {...settings}
          >
            {product.images.map((image) => (
              <div key={image} style={{ display: 'flex', justifyContent: 'center', borderRadius: '12px' }}>
                <ReactImageMagnify
                  enlargedImagePosition="over"
                  enlargedImageContainerDimensions={{ width: '100%', height: '100%' }}
                  enlargedImageContainerStyle={{ zIndex: 10, objectFit: 'contain' }}
                  hintTextTouch="Appuyez pour agrandir"
                  imageStyle={{
                    objectFit: 'contain',
                    overflow: 'hidden',
                    maxHeight: '300px',
                    height: '300px',
                    borderRadius: '25px',
                  }}
                  {...{
                    smallImage: {
                      alt: `Photo du produit ${product.title}`,
                      isFluidWidth: true,
                      src: getProductImageSrc(image),
                      height: 248,
                      width: 320,
                    },
                    largeImage: {
                      src: getProductImageSrc(image),
                      width: 900,
                      height: 1350,
                    },
                  }}
                />
              </div>
            ))}
          </Slider>
          <Box
            sx={{
              width: '100%',
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
            {...handlers}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
                alignItems: product.oldPrice ? 'top' : 'center',
                mt: 2,
              }}
            >
              <Typography
                variant="body2"
                component="a"
                fontSize={13}
                fontWeight={500}
                lineHeight={1.6}
                sx={{ textTransform: 'uppercase', textDecoration: 'none' }}
                href={product.brandLink}
                target="_blank"
              >
                {product.brand}
              </Typography>
              {product.oldPrice ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'end',
                    width: '100%',
                    gap: '6px',
                    pr: '8px',
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end', gap: '8px' }}>
                    <Typography
                      variant="body1"
                      component="p"
                      sx={{ fontSize: '18px', lineHeight: '19.2px', fontWeight: 700, textAlign: 'right' }}
                    >
                      {product.price}€
                    </Typography>
                    <Typography
                      variant="body1"
                      component="p"
                      sx={{
                        fontSize: '14px',
                        lineHeight: '16.2px',
                        fontWeight: 700,
                        textDecoration: 'line-through',
                        textAlign: 'right',
                      }}
                    >
                      {product.oldPrice}€
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{ fontSize: '12px', lineHeight: '14.2px', fontWeight: 400, color: 'black', textAlign: 'right' }}
                  >
                    {product.deliveryPrice && product.deliveryPrice > 0
                      ? `Frais de port : ${product.deliveryPrice}€`
                      : 'Livraison offerte'}
                  </Typography>
                </Box>
              ) : (
                <Typography variant="body2" component="p" fontSize={18} fontWeight={700} lineHeight={1.6} color="black">
                  {product.price}€
                </Typography>
              )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography
                variant="h1"
                component="h1"
                sx={{ fontSize: '20px', lineHeight: '24px', fontWeight: 700, color: 'black' }}
              >
                {product.title.replaceAll('votre animal', animal?.name || 'votre animal')}
              </Typography>
              {product.promotion && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '18px',
                    px: '16px',
                    py: '2px',
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: '16.8px',
                    color: theme.palette.secondary.main,
                    border: `2px solid ${theme.palette.secondary.main}`,
                  }}
                >
                  -{product.promotion}%
                </Box>
              )}
            </Box>
            {splittedDescription && splittedDescription.length > 0 && (
              <Typography
                variant="body1"
                component="p"
                sx={{
                  fontSize: '16px',
                  lineHeight: '20px',
                  color: 'black',
                  mb: 1,
                  ul: { display: 'flex', flexDirection: 'column', gap: 1, pl: '16px' },
                  ol: { display: 'flex', flexDirection: 'column', gap: 1, pl: '16px' },
                }}
                dangerouslySetInnerHTML={{ __html: splittedDescription[0] }}
              ></Typography>
            )}
            <Typography
              variant="body2"
              component="p"
              sx={{ fontSize: '14px', lineHeight: '20px', mb: 1, fontWeight: 600, fontStyle: 'italic' }}
            >
              Livraison en France métropolitaine
              <br />
              Expedié sous 48h
            </Typography>
            {product.usage && (
              <ExpandableCard title="Conseils d'utilisation">
                <Typography
                  variant="body1"
                  component="p"
                  sx={{
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: 'black',
                    mt: 2,
                    ul: { display: 'flex', flexDirection: 'column', gap: 1, pl: '16px' },
                    ol: { display: 'flex', flexDirection: 'column', gap: 1, pl: '16px' },
                  }}
                  dangerouslySetInnerHTML={{ __html: product.usage }}
                ></Typography>
              </ExpandableCard>
            )}
            {splittedDescription && splittedDescription.length > 1 && (
              <ExpandableCard title="Détails">
                <Typography
                  variant="body1"
                  component="p"
                  sx={{
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: 'black',
                    mt: 2,
                    ul: { display: 'flex', flexDirection: 'column', gap: 1, pl: '16px' },
                    ol: { display: 'flex', flexDirection: 'column', gap: 1, pl: '16px' },
                  }}
                >
                  {splittedDescription?.map((line, index) => {
                    if (index > 0)
                      return (
                        <span
                          key={index}
                          style={{ display: 'block', lineHeight: '20px', marginBottom: '8px' }}
                          dangerouslySetInnerHTML={{ __html: line }}
                        ></span>
                      )
                  })}
                </Typography>
              </ExpandableCard>
            )}
            {hasCharacteristics && (
              <ExpandableCard title="Caractéristiques">
                {product.dimensions && (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, gap: '10px' }}>
                    <Typography variant="body2" component="p" fontSize={13} lineHeight={1.6} color="black">
                      Dimensions
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      fontSize={13}
                      lineHeight={1.6}
                      color="black"
                      textAlign="right"
                    >
                      {product.dimensions}
                    </Typography>
                  </Box>
                )}
                {product.contenance && (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, gap: '10px' }}>
                    <Typography variant="body2" component="p" fontSize={13} lineHeight={1.6} color="black">
                      Contenance
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      fontSize={13}
                      lineHeight={1.6}
                      color="black"
                      textAlign="right"
                    >
                      {product.contenance}
                    </Typography>
                  </Box>
                )}
                {product.composition && (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, gap: '10px' }}>
                    <Typography variant="body2" component="p" fontSize={13} lineHeight={1.6} color="black">
                      Composition
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      fontSize={13}
                      lineHeight={1.6}
                      color="black"
                      textAlign="right"
                    >
                      {product.composition}
                    </Typography>
                  </Box>
                )}
                {product.color && (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, gap: '10px' }}>
                    <Typography variant="body2" component="p" fontSize={13} lineHeight={1.6} color="black">
                      Couleur
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      fontSize={13}
                      lineHeight={1.6}
                      color="black"
                      textAlign="right"
                    >
                      {product.color}
                    </Typography>
                  </Box>
                )}
                {product.length && (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, gap: '10px' }}>
                    <Typography variant="body2" component="p" fontSize={13} lineHeight={1.6} color="black">
                      Durée
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      fontSize={13}
                      lineHeight={1.6}
                      color="black"
                      textAlign="right"
                    >
                      {product.length.replaceAll('votre animal', animal?.name || 'votre animal')}
                    </Typography>
                  </Box>
                )}
              </ExpandableCard>
            )}
            {product.products &&
              product.products.length > 0 &&
              product.products.map((p) => (
                <ExpandableCard title={p.title} key={`${p.title}-${product.species[0]}`}>
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{
                      fontSize: '16px',
                      lineHeight: '20px',
                      color: 'black',
                      mt: 2,
                      ul: { display: 'flex', flexDirection: 'column', gap: 1, pl: '16px' },
                      ol: { display: 'flex', flexDirection: 'column', gap: 1, pl: '16px' },
                    }}
                    dangerouslySetInnerHTML={{ __html: p.details }}
                  ></Typography>
                </ExpandableCard>
              ))}
            {product.brandDetails && (
              <ExpandableCard title={product.brand}>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: 'black',
                    mt: 2,
                    ul: { display: 'flex', flexDirection: 'column', gap: 1, pl: '16px' },
                    ol: { display: 'flex', flexDirection: 'column', gap: 1, pl: '16px' },
                  }}
                  dangerouslySetInnerHTML={{ __html: product.brandDetails }}
                ></Typography>
              </ExpandableCard>
            )}
            {product.compositionDetails && (
              <ExpandableCard title="Composition">
                <Typography
                  variant="body1"
                  component="p"
                  sx={{
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: 'black',
                    mt: 2,
                    ul: { display: 'flex', flexDirection: 'column', gap: 1, pl: '16px' },
                    ol: { display: 'flex', flexDirection: 'column', gap: 1, pl: '16px' },
                  }}
                  dangerouslySetInnerHTML={{ __html: product.compositionDetails }}
                ></Typography>
              </ExpandableCard>
            )}
            {product.nutritionalDetails && (
              <ExpandableCard title="Faits nutritionnels">
                <Typography
                  variant="body1"
                  component="p"
                  sx={{
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: 'black',
                    mt: 2,
                    ul: { display: 'flex', flexDirection: 'column', gap: 1, pl: '16px' },
                    ol: { display: 'flex', flexDirection: 'column', gap: 1, pl: '16px' },
                  }}
                  dangerouslySetInnerHTML={{ __html: product.nutritionalDetails }}
                ></Typography>
              </ExpandableCard>
            )}
          </Box>
          <div style={{ height: '118px' }}></div>
          <Box
            component={'form'}
            onSubmit={handleSubmit(handleAddToCart)}
            sx={{
              position: 'fixed',
              bottom: 0,
              left: { xs: 0, md: '240px' },
              right: 0,
              backgroundColor: 'white',
              p: 4,
              pt: 3,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              alignItems: 'center',
              borderTop: '1px solid #E0E0E0',
              boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.10)',
            }}
          >
            {product.colors && (
              <ControlledSelectField
                control={control}
                fieldName="color"
                error={undefined}
                label="Couleur"
                options={product.colors.map((color) => ({ value: color, label: color }))}
                requiredRule="Vous devez choisir une couleur"
                size="small"
              />
            )}
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 2, width: '100%' }}>
              <ControlledSelectField
                control={control}
                fieldName="quantity"
                error={undefined}
                label="Quantité"
                options={
                  product.quantities
                    ? product.quantities.map((quantity) => {
                        return { value: quantity, label: quantity.toString() + ' x 70g' }
                      })
                    : [
                        { value: 1, label: '1' },
                        { value: 2, label: '2' },
                        { value: 3, label: '3' },
                        { value: 4, label: '4' },
                        { value: 5, label: '5' },
                      ]
                }
                requiredRule=""
                size="small"
                sx={{ maxWidth: '30%' }}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  px: '20px',
                  py: '13px',
                  borderRadius: '200px',
                  color: theme.palette.secondary.main,
                  fontSize: '16px',
                  lineHeight: '19.2px',
                  fontWeight: 600,
                  boxShadow: 'none',
                }}
              >
                {`Ajouter au panier`}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
