import { Person } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Typography, TextField, Grid } from '@mui/material'
import { AxiosError } from 'axios'
import React, { FormEvent, useState } from 'react'
import { useMutation } from 'react-query'
import { AuthClient } from '../../interactors/clients/AuthClient'
import { useProgressiveImage } from '../../utils/hooks/useProgressiveImage'
import { blue } from '../theme'
import Background from '../Login/login-bg.png'
import placeholder from '../Login/login-bg-compressed.png'

interface ErrorResponse {
  message: string
}

export const RequestResetPasswordPage: React.FC = () => {
  const [email, setEmail] = React.useState('')
  const [serverError, setServerError] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)

  const createPasswordMutation = useMutation(
    async (params: { email: string }) => {
      return await AuthClient.requestPasswordReset(params.email)
    },
    {
      onSuccess: () => {
        setIsSuccess(true)
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        console.error({ error })
        setServerError(
          `Une erreur est survenue lors de la modification de votre mot de passe : ${
            error.response?.data?.message ?? 'Une erreur inconnue est survenue'
          }`
        )
      },
    }
  )

  const handleResetPasswordRequest = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    setServerError('')
    return createPasswordMutation.mutate({
      email: email.toLowerCase().trim(),
    })
  }

  const loadedBackground = useProgressiveImage(Background)

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: { md: 'row', xs: 'column' },
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        '@media orientation: landscape': {
          overflow: 'auto',
        },
      }}
    >
      <Box sx={{ height: { md: 0, sm: 100, xs: 100 } }}></Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: { md: '100%', xs: '50%' },
          width: { md: '50%', xs: '100%' },
          mt: { md: '40vh', xs: 0 },
        }}
      >
        <Box flexGrow="1" margin="auto" padding="0px 2%" boxSizing="border-box" paddingLeft={{ lg: 10 }}>
          {isSuccess ? (
            <Typography
              variant="h4"
              sx={{ color: blue, fontWeight: 600, fontSize: { xs: '1rem', sm: '1.4rem' }, marginBottom: 2 }}
            >
              {'Nous venons de vous envoyer un lien de réinitialisation de mot de passe par email.'}
            </Typography>
          ) : (
            <div>
              <Box
                sx={{
                  margin: 'auto',
                  '@media orientation: portrait': {
                    maxWidth: { md: '600px', sm: '80vh' },
                  },
                  '@media orientation: landscape': {
                    maxWidth: { md: '600px', sm: '80vw' },
                  },
                  maxWidth: { md: '600px' },
                  color: 'white',
                  marginTop: { md: 0, xs: 5 },
                  width: { md: '100%', sm: '600px' },
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ color: blue, fontWeight: 600, fontSize: { xs: '1rem', sm: '1.4rem' }, marginBottom: 2 }}
                >
                  {"Veuillez entrer l'email que vous avez communiqué à l'association:"}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ width: { md: '100%', xs: '600px' }, maxWidth: { md: '400px', xs: '95vw' } }}>
                  <form onSubmit={handleResetPasswordRequest}>
                    <div>
                      <Box marginTop="0.5rem">
                        <TextField
                          fullWidth
                          type={'text'}
                          label="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <div style={{ marginRight: '8px' }}>
                                <Person />
                              </div>
                            ),
                          }}
                        />
                      </Box>
                    </div>

                    <div>
                      {serverError && (
                        <Grid item>
                          <Typography color="error">{serverError}</Typography>
                        </Grid>
                      )}
                      <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem">
                        <Box position="relative" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                          <LoadingButton
                            variant="contained"
                            id="login-button"
                            type="submit"
                            loading={createPasswordMutation.isLoading}
                            sx={{ py: 2, px: 4 }}
                          >
                            Demander un lien de réinitialisation
                          </LoadingButton>
                        </Box>
                      </Box>
                    </div>
                  </form>
                </Box>
              </Box>
            </div>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          zIndex: 1,
          pointerEvents: 'none',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: { md: '100vh', xs: '100%' },
          width: { md: '50%', xs: '100%' },
          backgroundImage: `url(${loadedBackground || placeholder})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: { md: 'left', xs: 'top' },
        }}
      ></Box>
    </Box>
  )
}
